// Layouts
// Global Layouts
import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import GlobalLayout from 'src/pages/Layout/GlobalLayout.vue';
// Layouts Admin
import DashboardLayoutMyaffilAdmin from 'src/pages/Layout/LayoutMyAffilAdmin/DashboardLayoutMyaffilAdmin.vue'
import DashboardLayoutMarketAdmin from 'src/pages/Layout/LayoutMarketAdmin/DashboardLayoutMarketAdmin.vue'
import DashboardLayoutDataAdmin from 'src/pages/Layout/LayoutDataAdmin/DashboardLayoutDataAdmin.vue';
import LayoutReelTimeStats from 'src/pages/Layout/LayoutMyAffilAdmin/LayoutReelTimeStats.vue'
import ScenariosLayoutWeedoCheckAdmin from 'src/pages/Layout/LayoutWeedoCheckAdmin/ScenariosLayoutWeedoCheckAdmin.vue'
// Layouts Editeur
import DashboardLayoutMyaffilEdit from 'src/pages/Layout/LayoutMyAffilEdit/DashboardLayoutMyaffilEdit.vue';
import DashboardLayoutMyCoregEdit from 'src/pages/Layout/LayoutMyCoregEdit/DashboardLayoutMyCoregEdit.vue';
import DashboardLayoutDatagEdit from 'src/pages/Layout/LayoutDataEdit/DashboardLayoutDataEdit.vue';
import ScenariosLayoutWeedoCheckEdit from 'src/pages/Layout/LayoutWeedoCheckEdit/ScenariosLayoutWeedoCheckEdit.vue';

// Layouts Annonceurs
import DashboardLayoutMyaffilAnnonceur from 'src/pages/Layout/LayoutMyaffilAnnonceur/DashboardLayoutMyaffilAnnoncer.vue';
import DashboardLayoutMarketAnnonceur from 'src/pages/Layout/LayoutMarketAnnonceur/DashboardLayoutMarketAnnoncer.vue';
import DashboardLayoutMongustaveAnnonceur from 'src/pages/Layout/LayoutMongustaveAnnonceur/DashboardLayoutMongustaveAnnoncer.vue';
import ScenariosLayoutWeedoCheckAnnonceur from 'src/pages/Layout/LayoutWeedoCheckAnnonceur/ScenariosLayoutWeedoCheckAnnonceur.vue';

import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';
import guest from './middleware/guest'
import urlFormater from './middleware/urlFormater'
import auth from './middleware/auth'
import authadmin from './middleware/authadmin'
import weedocheck from './middleware/weedocheck'

// Globale Editeur Pages
const AppelaFactureEdit = () =>
    import ( /* webpackChunkName: "pages-gb-af" */ 'src/pages/GlobalEditeur/Appelfacture.vue');

// Globale Annonceur Pages
const ListeOIdisponibleAnnonceur = () =>
    import ( /* webpackChunkName: "pages-gb-af" */ 'src/pages/GlobalAnnonceur/ListeOIdisponible.vue');

const FactureAnnonceur = () =>
    import ( /* webpackChunkName: "pages-gb-af" */ 'src/pages/GlobalAnnonceur/Facture.vue');

////////////////////////////////////////////// DEMO  /////////
const FactureAnnonceur_NEW = () =>
    import ( /* webpackChunkName: "pages-gb-an" */ 'src/pages/GlobalAnnonceur/Facture_NEW.vue');
////////////////////////////////////////////// DEMO  /////////

// Globale Admin Pages
const ListPrePayment = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/GlobalAdmin/ListPrePayment.vue');
const LogsAdmin = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/GlobalAdmin/Logs.vue');

const Advance_Client = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/Report/Advance_Client.vue');

const Report = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/Report/Report.vue');

const Rapport = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/Report/Rapport.vue');

const RapportCa_Client = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/Report/RapportCa_Client.vue');

const RapportCoreg = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/Report/RapportCoreg.vue');

const RapportMongustave = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/Report/Rapport_Mongustave.vue');

const Payment = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/Report/Payment.vue');

const Facture = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/Report/Facture.vue');

const FactureMongustave = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/Report/FactureMongustave.vue');

const FactureWeedoMarket = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/Report/FactureWeedoMarket.vue');

const ListSupports = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Supports/ListSupports.vue');

const AddCampagne = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Campagnes/AddCampagne.vue');
const EditCampagne = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Campagnes/EditCampagne.vue');

const Pixels = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Campagnes/Pixels.vue');
const Visuel = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Campagnes/Visuel.vue');
const ListeCampagnes = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Campagnes/ListeCampagnes.vue');
const AllListeCampagnes = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Campagnes/AllListeCampagnes.vue');
const Member = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/GlobalAdmin/Member/Member.vue');
const ListeMembers = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/GlobalAdmin/Member/ListeMembers.vue');
const AddOI = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/GlobalAdmin/OI/AddOi.vue');
const UpdateOi = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/GlobalAdmin/OI/UpdateOi.vue');
const ListeOIdisponible = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/GlobalAdmin/ListeOIdisponible.vue');
const suiviTeletechLeads = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/GlobalAdmin/suiviTeletechLeads.vue');
const ListeShoots = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/ListeShoots.vue');
const StatsShoots = () =>
    import( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsShoots.vue');
const StatsMG = () =>
    import ( /* webpackChunkName: "pages-mg-ad" */ 'src/pages/GlobalAdmin/Stats/StatsMG.vue');
const DetailLeadsByEditeur = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/detailLeadsEditeur.vue');
const DetailLeadsByEditeur_Admin = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/detailLeadsEditeur_Admin.vue');
const DetailLeadsByEditeur_Traking = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/detailLeadsEditeur_Traking.vue');
const DetailLeads = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/detailLeads.vue');
const Objectifs = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Objectifs.vue');
const TopTM = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/TopTM.vue');
const StatsLayout = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsLayout.vue');
const IntegrationLayout = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/IntegrationPrg/IntegrationLayout.vue');

/*                                 FCM Notifications                                           */
const PushNotifications = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/PushNotification/PushNotifications.vue');
const StatsPushNotifications = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/PushNotification/StatsPushNotifications.vue');
const LogsPushNotifications = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/PushNotification/LogsPushNotifications.vue');

/*                                 Interaction with bedrock                                     */
const BedrockInteraction = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/GlobalAdmin/BedrockInteraction/BedrockInteraction.vue');

/*
/*                                 LTV                                     */
const ListeTest = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/GlobalAdmin/Liste/LTV.vue');

/*
                              Integration                                           */
const AddProgramme = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/IntegrationPrg/AddPrograme.vue');
const AddVisuel = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/IntegrationPrg/AddVisuel.vue');
const DuplicateProgram = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/IntegrationPrg/DuplicateProgram.vue');
/*                              END Integration                                           */

/*                                 Stats                                           */
const StatsGlobales = () =>
    import( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsGlobales.vue');
const StatsGlobalesTracking = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsGlobalesWithTracking.vue');
const StatsCampagnes = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsCampagnes.vue');
const StatsDetaillees = () =>
    import( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsDetaillees.vue');
const StatsDetailleesTracking = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsDetailleesWithTracking.vue');
const StatsCAEditeur = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsCAEditeur.vue');
const StatsCAProgramme = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsCAProgramme.vue');
const StatsLeadMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsLeadMarket.vue');
const StatsCaParLeviers = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsCaParLeviers.vue');
const StatsTopCaAnOrAfAdmin = () =>
    import( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsTopCaAnOrAf.vue');
const StatsExtractEditeurs = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsExtractEditeurs.vue');
const StatsGlobalAdvanceAdmin = () =>
import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsGlobalAdvance.vue');
const StatsGlobales_Admin = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsGlobales_Admin.vue');

const StatsDetaillees_Admin = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/GlobalAdmin/Stats/StatsDetaillees_Admin.vue');
/*                              END Stats                                           */

// Dashboard pages
const Dashboard_admin = () =>
    import ( /* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard-admin.vue');

const Dashboard_editeurs = () =>
    import ( /* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard-editeurs.vue');

const Dashboard_annonceur = () =>
    import ( /* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/dashboard-annonceur.vue');

const Dashboard_mongustave_ad = () =>
    import ( /* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard-mongustave-ad.vue');

const Dashboard_mongustave_an = () =>
    import ( /* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard-mongustave-an.vue');

const Dashboard_cadeaux = () =>
    import( /* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard-cadeaux.vue');

//  Home Core & DataDeal
const Home_WeedoCoreg = () =>
    import ( /* webpackChunkName: "dashboard" */ 'src/pages/Pages/HomeCoreg&Data/HomeCoreg.vue');
const Home_WeedoData = () =>
    import ( /* webpackChunkName: "dashboard" */ 'src/pages/Pages/HomeCoreg&Data/HomeData.vue');
//  Home Core & DataDeal


// Profile Component
const User = () =>
    import ( /* webpackChunkName: "pages" */ 'src/pages/Pages/UserProfile.vue');

// Authentification Components
const Login = () =>
    import ( /* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');
const ForgotPassword = () =>
    import ( /* webpackChunkName: "pages" */ 'src/pages/Pages/ForgotPassword.vue');
const ResetPassword = () =>
    import ( /* webpackChunkName: "pages" */ 'src/pages/Pages/ResetPassword.vue');

//*                  reset password for old user                 *//
const ResetPasswordOldUser = () =>
    import ( /* webpackChunkName: "pages" */ 'src/pages/Pages/ForgotPasswordOldUser.vue');
//*                  reset password for old user                 *//

const ValidateEmail = () =>
    import ( /* webpackChunkName: "pages" */ 'src/pages/Pages/ValidateEmail.vue');
const checktocken = () =>
    import ( /* webpackChunkName: "pages" */ 'src/pages/Pages/checktocken.vue');
const apercu_template = () =>
    import ( /* webpackChunkName: "pages" */ 'src/pages/Pages/apercu_template.vue');
const Register = () =>
    import ( /* webpackChunkName: "pages" */ 'src/pages/Pages/Register.vue');
/* ************************************         Admin       **************************************    */
const DashboardMyAffilAdmin = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/Pages/MyAffilAdmin/Dashboard.vue');
const ProgramsListMyAffilAdmin = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/Pages/MyAffilAdmin/ProgramsList.vue');
const PostulerListMyAffilAdmin = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/Pages/MyAffilAdmin/ListePostule.vue');
const ProgramVisualierMyAffilAdmin = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/Pages/MyAffilAdmin/ProgramVisualier.vue');
const uploadKitMyAffilAdmin = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/Pages/MyAffilAdmin/uploadKit.vue');
// LeadMarket Admin Components
const StatGustaveGlobalMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/statGustave/Global.vue');
const StatGustaveGlobalMarketAdmin_RealTime = () =>
    import ( /* webpackChunkName: "reel-time-stats" */ 'src/pages/Pages/MyMarketAdmin/statGustave/Global_realtime.vue');
const StatWeedoMarketAdmin_RealTime = () =>
    import ( /* webpackChunkName: "reel-time-stats" */ 'src/pages/Pages/MyMarketAdmin/Categories/Categories_realtime.vue');
const StatGustaveClientsMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/statGustave/Clients.vue');
const StatGustaveResponseTimeWS = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/statGustave/ResponseTimeWS.vue');
const CategoriesMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/Categories/Categories.vue');
const RapportCategoriesMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/Categories/Rapport.vue');
const ADDCategoriesMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/Categories/Add.vue');
const EditCategoriesMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/Categories/Edit.vue');
const StatDetailleesMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/StatDetaillees.vue');
const PromoCodesMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/PromoCodes.vue');
const StockMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/Stock.vue');
const TransactionsMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/Transactions.vue');
const CommandesMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/Commandes/Commandes.vue');
const DetailsCommandesMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/Commandes/detailsCommande.vue');
const EditCommandesMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/Commandes/Edit.vue');
const SuiviMonitoringMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/Suivi_Monitoring.vue');
const OggoDataMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/OggoData.vue');
const leadsDevalidesMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/leadsDevalides.vue');
const surveillanceLeadsMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/surveillanceLeads.vue');
const surveillanceLeadsMarketAdmin_2 = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/surveillanceLeads2.vue');
const ComptabiliteMarketAdmin = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/Comptabilite.vue');
const FacturesAvoir = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Report/FactureAvoir/FactureAvoir.vue');
const PhoneNumberValidation = () =>
    import ( /* webpackChunkName: "pages-wm-ad" */ 'src/pages/Pages/MyMarketAdmin/PhoneNumberValidation.vue');

// Weedo Data Admin Components
const VueStockDataAdmin = () =>
    import ( /* webpackChunkName: "pages-wd-ad" */ 'src/pages/Pages/WeedoDataAdmin/VueStock.vue');

//
const UnsubscriberStats = () =>
    import ( /* webpackChunkName: "pages-wd-ad" */ 'src/pages/Pages/WeedoDataAdmin/UnsubscriberStats.vue');


const VerifyEmailDataAdmin = () =>
    import ( /* webpackChunkName: "pages-wd-ad" */ 'src/pages/Pages/WeedoDataAdmin/VerifyEmail.vue');

const VerifyEmailDataAdminConfirm = () =>
    import ( /* webpackChunkName: "pages-wd-ad" */ 'src/pages/Pages/WeedoDataAdmin/VerifyEmailConfirm.vue');



const LeadDesabonnerDataAdmin = () =>
    import ( /* webpackChunkName: "pages-wd-ad" */ 'src/pages/Pages/WeedoDataAdmin/LeadDesabonner.vue');

// Weedo Check Admin Components
const ScenariosWeedoCheckAdmin = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/Pages/WeedoCheckAdmin/Scenarios.vue');
const EventsWeedoCheckAdmin = () =>
    import ( /* webpackChunkName: "pages-wp-ad" */ 'src/pages/Pages/WeedoCheckAdmin/Events.vue');

/* ************************************         Editeur       ************************************    */
// My Affil Editeur Components
const DashboardMyAffilEdit = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/MyAffilEditeur/Dashboard.vue');
/*------------- Stats -------------*/
const StatsLayoutMyAffilEdit = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/MyAffilEditeur/Stats/StatsLayout.vue');
const InfosBancaire = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/MyAffilEditeur/InfosBancaire/InfosBancaire.vue');
const AffilieFrance = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/MyAffilEditeur/InfosBancaire/AffilieFrance.vue');
const AffilieInternational = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/MyAffilEditeur/InfosBancaire/AffilieInternational.vue');

const StatsValides = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/Pages/MyAffilAdmin/StatsValides/StatsValides.vue');
const StatsValidesMyAffil = () =>
    import ( /* webpackChunkName: "pages-gb-ad" */ 'src/pages/Pages/MyAffilAdmin/StatsValides/StatsValidesMyAffil.vue');
const StatsValidesMyCoreg = () =>
    import ( /* webpackChunkName: "pages-gb-af" */ 'src/pages/Pages/MyAffilAdmin/StatsValides/StatsValidesMyCoreg.vue');

const StatsGlobalesMyAffilEdit = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/MyAffilEditeur/Stats/StatsGlobales.vue');
const StatsCampagnesMyAffilEdit = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/MyAffilEditeur/Stats/StatsCampagne.vue');

const DetailLeadsForEditeur = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/MyAffilEditeur/Stats/DetailsLeads.vue');

// Weedo Check Editeur Components
const ScenariosWeedoCheckEdit = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/WeedoCheckEditeur/Scenarios.vue');
const EventsWeedoCheckEdit = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/WeedoCheckEditeur/Events.vue');



/*------------- Stats -------------*/
const ProgramsListMyAffilEdit = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/MyAffilEditeur/ProgramsList.vue');
const ProgramVisualierMyAffilEdit = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/MyAffilEditeur/ProgramVisualier.vue');
const ListeShootsMyAffilEditeur = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/MyAffilEditeur/MesShoots.vue');
const SupportsListMyAffilEdit = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/MyAffilEditeur/ListSupports.vue');
const CampagnesListMyAffilEdit = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/MyAffilEditeur/Stats/ListeCampagnes.vue');
const CampagnesVisuelMyAffilEdit = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/MyAffilEditeur/Stats/CampagnesVisuel.vue');
const StatsShootsMyAffilEdit = () =>
    import ( /* webpackChunkName: "pages-wp-af" */ 'src/pages/Pages/MyAffilEditeur/Stats/StatsShoots.vue');


// My Coreg Editeur Components
const DashboardMyCoregEdit = () =>
    import ( /* webpackChunkName: "pages-wc-af" */ 'src/pages/Pages/MyCoregEditeur/Dashboard.vue');

// Data Editeur Components
const DashboardDataEdit = () =>
    import ( /* webpackChunkName: "pages-wd-af" */ 'src/pages/Pages/MyDataEditeur/Dashboard.vue');

/* ************************************         Annonceur       ************************************    */
// My Affil Annonceur Components
const DashboardMyAffilAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wp-an" */ 'src/pages/Pages/MyAffilAnnonceur/Dashboard.vue');
const ProgramsListMyAffilAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wp-an" */ 'src/pages/Pages/MyAffilAnnonceur/ProgramsList.vue');
const ProgramVisualierMyAffilAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wp-an" */ 'src/pages/Pages/MyAffilAnnonceur/ProgramVisualier.vue');
const ListeShootsMyAffilAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wp-an" */ 'src/pages/Pages/MyAffilAnnonceur/ListeShoots.vue');
/*------------- Stats -------------*/
const StatsLayoutMyAffilAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wp-an" */ 'src/pages/Pages/MyAffilAnnonceur/Stats/StatsLayout.vue');
const StatsGlobalesMyAffilAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wp-an" */ 'src/pages/Pages/MyAffilAnnonceur/Stats/StatsGlobales.vue');
const StatsDetailsMyAffilAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wp-an" */ 'src/pages/Pages/MyAffilAnnonceur/Stats/StatsDetaillees.vue');
const paiement = () =>
    import ( /* webpackChunkName: "pages-gb-an" */ 'src/pages/Pages/MyAffilAnnonceur/Paiements/Paiement.vue');
const new_paiement = () =>
    import ( /* webpackChunkName: "pages-gb-an" */ 'src/pages/Pages/MyAffilAnnonceur/Paiements/New_Paiement.vue');
const new_paiementv2 = () =>
    import ( /* webpackChunkName: "pages-gb-an" */ 'src/pages/Pages/MyAffilAnnonceur/Paiements/New_PaiementV2.vue');

/*------------- Stats -------------*/

// Market Annonceur Components
const HomePartnersMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/Partners/Home.vue');
const EciliaPartnersMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/Partners/Ecilia.vue');
const OggodataPartnersMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/Partners/Oggodata.vue');
const CourtigoPartnersMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/Partners/Courtigo.vue');
const SoSoftPartnersMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/Partners/SoSoft.vue');
const InsquaryPartnersMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/Partners/Insquary.vue');
const ErnestPartnersMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/Partners/Ernest.vue');
const BubbleInPartnersMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/Partners/BubbleIn.vue');
const RingoverPartnersMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/Partners/Ringover.vue');
const DashboardMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/Dashboard.vue');
const MyLeadMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/MyLeads.vue');
const MyCommandeMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/MyCommande.vue');
const MyFiltersMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/MyFilters.vue');
const AddCommandeMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/AddCommande.vue');
const AddFilterMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/AddFilter.vue');
const EditFilterMarketAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/EditFilter.vue');
const MyPrepaymentsAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/MyPrepayments.vue');
const MyTarifsAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wm-an" */ 'src/pages/Pages/MyMarketAnnonceur/MyTarifs.vue');

// Mongustave Annonceur Components
const ExtractMerMgAnnonceur = () =>
    import ( /* webpackChunkName: "pages-mg-an" */ 'src/pages/Pages/MongustaveAnnonceur/ExtractMer.vue');

// Weedo Check Annonceur Components
const ScenariosWeedoCheckAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wp-an" */ 'src/pages/Pages/WeedoCheckAnnonceur/Scenarios.vue');
const EventsWeedoCheckAnnonceur = () =>
    import ( /* webpackChunkName: "pages-wp-an" */ 'src/pages/Pages/WeedoCheckAnnonceur/Events.vue');

// Profile Route
let pagesMenu = {
    path: '/pages',
    component: DashboardLayout,
    name: 'Pages',
    redirect: '/pages/user',
    children: [{
        path: 'user',
        name: 'User Profile',
        components: { default: User },
        meta: {
            title: 'Weedoit - Profile',
            middleware: [
                auth
            ]
        }
    }]
};
let bedrockInterface = {
    path: '/bedrock',
    component: DashboardLayout,
    name: 'Bedrock',
    redirect: '/bedrock/interactions',
    children: [{
        path: 'interactions',
        name: 'Intercation',
        components: { default: BedrockInteraction },
        meta: {
            title: 'Weedoit - Intercation avec Bedrock',
            middleware: [
                authadmin
            ]
        }
    }]
};

// Authentification Routes
let authPages = {
    path: '/',
    component: AuthLayout,
    name: '',
    children: [{
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                middleware: [
                    guest
                ]
            }
        },
        {
            path: '/register',
            name: 'Register',
            component: Register,
            meta: {
                middleware: [
                    guest
                ]
            }
        },
        {
            path: '/forgotpassword',
            name: 'Forgotpassword',
            component: ForgotPassword,
            meta: {
                middleware: [
                    guest
                ]
            }
        },
        {
            path: '/resetPassword',
            name: 'ResetPassword',
            component: ResetPassword,
            meta: {
                middleware: [
                    guest
                ]
            }
        },
        {
            path: '/resetPasswordOldUser',
            name: 'resetPasswordOldUser',
            component: ResetPasswordOldUser,
            meta: {
                middleware: [
                    guest
                ]
            }
        },
        {
            path: '/validateEmail',
            name: 'ValidateEmail',
            component: ValidateEmail,
            meta: {
                middleware: [
                    guest
                ]
            }
        }
    ]
};

/* ************************************         Admin       **************************************    */
// My Affil Admin
let AffilAdmin = {
    path: '/myaffil/admin',
    component: DashboardLayoutMyaffilAdmin,
    name: '',
    children: [{
            path: 'dashboard',
            name: 'Dashboard MyAffil Admin',
            components: { default: DashboardMyAffilAdmin },
            meta: {
                title: 'Weedoit - Tableau de bord PERF',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'prgramslist',
            name: 'liste programmes My Affil Admin',
            components: { default: ProgramsListMyAffilAdmin },
            meta: {
                title: 'Weedoit - Liste des Programmes PERF',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'postulelist',
            name: 'liste postule My Affil Admin',
            components: { default: PostulerListMyAffilAdmin },
            meta: {
                title: 'Weedoit - Liste Postule PERF',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'viewprogram',
            name: 'Visualiser programme My Affil Admin',
            components: { default: ProgramVisualierMyAffilAdmin },
            meta: {
                title: "Weedoit - Visualiser Programme PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'Shootlist',
            name: 'liste Shoot My Affil Admin',
            components: { default: ListeShoots },
            meta: {
                title: "Weedoit - Liste Shoots PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'stats_shoots/:id_campagne',
            name: 'stats_shoots',
            components: { default: StatsShoots },
            meta: {
                title: "Weedoit - Stats Shoots PERF",
                middleware: [
                    authadmin
                ]
            }
        },

        {
            path: 'DetailLeads',
            name: 'stats Detail Leads day',
            components: { default: DetailLeadsByEditeur },
            meta: {
                title: "Weedoit - Stats Leads PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'DetailLeads_Admin',
            name: 'stats Detail Leads day admin',
            components: { default: DetailLeadsByEditeur_Admin },
            meta: {
                title: "Weedoit - Stats Leads PERF new tracking",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'DetailLeads_Traking',
            name: 'stats Detail Leads by new traking',
            components: { default: DetailLeadsByEditeur_Traking },
            meta: {
                title: "Weedoit - Stats Leads PERF new tracking",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'stats_globales_editeur/:id_client/:society',
            name: 'stats_globales_editeur',
            components: { default: StatsShoots },
            meta: {
                title: "Weedoit - Stats Leads PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'detailLeads/:id_client&:date_start&:date_end',
            name: 'stats Detail Leads',
            components: { default: DetailLeads },
            meta: {
                title: "Weedoit - detail Leads PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'integration',
            name: '',
            components: { default: IntegrationLayout },
            children: [{
                    path: 'ajouterprogramme',
                    name: 'Ajouter un Programme',
                    components: { default: AddProgramme },
                    meta: {
                        title: "Weedoit - Ajouter un Programme PERF",
                        middleware: [
                            authadmin
                        ]
                    }
                },
                {
                    path: 'ajoutervisuel',
                    name: 'Ajouter un Visuel',
                    components: { default: AddVisuel },
                    meta: {
                        title: "Weedoit - Ajouter un Visuel PERF",
                        middleware: [
                            authadmin
                        ]
                    }
                },
                {
                    path: 'dupliquerprogramme',
                    name: 'Dupliquer un Programme',
                    components: { default: DuplicateProgram },
                    meta: {
                        title: "Weedoit - Dupliquer un Programme PERF",
                        middleware: [
                            authadmin
                        ]
                    }
                }
            ],
        },
        {
            path: 'objectifs',
            name: 'Objectifs',
            components: { default: Objectifs },
            meta: {
                title: "Weedoit - Objectifs PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'toptm',
            name: 'Top traffic manager',
            components: { default: TopTM },
            meta: {
                title: "Weedoit - Top traffic manager PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            name: '',
            path: 'stats',
            components: { default: StatsLayout },
            children: [{
                    path: 'statsglobales',
                    name: 'Stats Globales',
                    components: { default: StatsGlobales },
                    meta: {
                        title: "Weedoit - Stats Globales PERF",
                        middleware: [
                            authadmin
                        ]
                    }
                },
                {
                    path: 'statsglobalestracking',
                    name: 'Stats Globales',
                    components: { default: StatsGlobalesTracking },
                    meta: {
                        title: "Weedoit - Stats Globales PERF",
                        middleware: [
                            authadmin
                        ]
                    }
                },
                {
                    path: 'statsdetaillees',
                    name: 'Stats détaillées',
                    components: { default: StatsDetaillees },
                    meta: {
                        title: "Weedoit - Stats détaillées PERF",
                        middleware: [
                            authadmin
                        ]
                    }
                },
                {
                    path: 'statsdetailleestracking',
                    name: 'Stats détaillées',
                    components: { default: StatsDetailleesTracking },
                    meta: {
                        title: "Weedoit - Stats détaillées PERF",
                        middleware: [
                            authadmin
                        ]
                    }
                },
                {
                    path: 'statscampagnes',
                    name: 'Stats Campagnes',
                    components: { default: StatsCampagnes },
                    meta: {
                        title: "Weedoit - Stats Campagnes PERF",
                        middleware: [
                            authadmin
                        ]
                    }
                },
                {
                    path: 'statscaediteur',
                    name: 'Stats CA par editeur',
                    components: { default: StatsCAEditeur },
                    meta: {
                        title: "Weedoit - Stats CA par editeur PERF",
                        middleware: [
                            authadmin
                        ]
                    }
                },
                {
                    path: 'statscaprogramme',
                    name: 'Stats CA par programme',
                    components: { default: StatsCAProgramme },
                    meta: {
                        title: "Weedoit - Stats CA par programme PERF",
                        middleware: [
                            authadmin
                        ]
                    }
                },
            ]

        },
        {
            path: 'stats/statsleadmarket',
            name: 'Stats LeadMarket Perf',
            components: { default: StatsLeadMarketAdmin },
            meta: {
                title: "Weedoit - Stats globales Lead Market PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'stats/statsAvancees',
            name: 'Stats globales avancées Perf',
            components: { default: StatsGlobalAdvanceAdmin },
            meta: {
                title: "Weedoit - Stats globales avancées PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'stats/extractEditeurs',
            name: 'Stats Extract Editeurs Perf',
            components: { default: StatsExtractEditeurs },
            meta: {
                title: "Weedoit - Stats Extract Editeurs PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'liste_campagnes/:id_client',
            name: 'liste_campagnes',
            components: { default: ListeCampagnes },
            meta: {
                title: "Weedoit - Liste Campagnes PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'liste_campagnes',
            name: 'all campagnes',
            components: { default: AllListeCampagnes },
            meta: {
                title: "Weedoit - Liste Campagnes PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'visuel/:identifiant/:nom_campagne/:id_ann',
            name: 'visuel',
            components: { default: Visuel },
            meta: {
                title: "Weedoit - Visuel PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'edit_campagne/:id_campagne',
            name: 'edit_campagne',
            components: { default: EditCampagne },
            meta: {
                title: "Weedoit - Modifier une Campagne PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'list_supports/:id_utilisateur',
            name: 'list_supports',
            components: { default: ListSupports },
            meta: {
                title: "Weedoit - Liste des Supports",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'add_campagne',
            name: 'add_campagne',
            components: { default: AddCampagne },
            meta: {
                title: "Weedoit - Ajouter une Campagne PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'pixels/:identifiant/:nom_campagne',
            name: 'pixels',
            components: { default: Pixels },
            meta: {
                title: "Weedoit - Pixels PERF",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'statsglobales_Admin',
            name: 'statsglobales_Admin',
            components: { default: StatsGlobales_Admin },
            meta: {
                title: "Weedoit - Stats Globale Admin",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'statsdetaillees_Admin',
            name: 'statsdetaillees_Admin',
            components: { default: StatsDetaillees_Admin },
            meta: {
                title: "Weedoit - Stats detaillees Admin",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'UploadImages',
            name: 'Upload Image Kit PERF',
            components: { default: uploadKitMyAffilAdmin },
            meta: {
                title: "Weedoit - Upload images kit non intégré Admin",
                middleware: [
                    authadmin
                ]
            }
        },
    ]
}

// MyMarket Admin
let MarketAdmin = {
    path: '/weedomarket/admin',
    component: DashboardLayoutMarketAdmin,
    name: '',
    children: [{
            path: 'stats/statGustave',
            name: 'Stats Global Mon Gustave Admin',
            components: { default: StatGustaveGlobalMarketAdmin },
            meta: {
                title: 'Weedoit - Statistiques Mon Gustave',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'stats/statClientsGustave',
            name: 'Stats Clients Mon Gustave Admin',
            components: { default: StatGustaveClientsMarketAdmin },
            meta: {
                title: 'Weedoit - Statistiques Mon Gustave par clients',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'stats/tempReponseWS',
            name: 'surveillance des webservices Mon Gustave Admin',
            components: { default: StatGustaveResponseTimeWS },
            meta: {
                title: 'Weedoit - surveillance des webservices Mon Gustave',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'stats/stats_mg',
            name: 'email transactionnel',
            components: { default: StatsMG },
            meta: {
                title: "Weedoit - Email Transactionnel",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'categories',
            name: 'Categorie LeadMarket Admin',
            components: { default: CategoriesMarketAdmin },
            meta: {
                title: 'Weedoit - Categories WEEDO MARKET',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'categories/rapport/:categorie_id/:categorie',
            name: 'Rapport Categorie LeadMarket Admin',
            components: { default: RapportCategoriesMarketAdmin },
            meta: {
                title: 'Weedoit - Rapport Categories WEEDO MARKET',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'creeCategorie',
            name: 'Ajoute Categorie LeadMarket Admin',
            components: { default: ADDCategoriesMarketAdmin },
            meta: {
                title: 'Weedoit - Ajouter Categorie WEEDO MARKET',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'editCategorie/:categorie_id',
            name: 'Edit Categorie LeadMarket Admin',
            components: { default: EditCategoriesMarketAdmin },
            meta: {
                title: 'Weedoit - Modifier Categorie WEEDO MARKET',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'StatsClients',
            name: 'statistiques Clients LeadMarket Admin',
            components: { default: StatDetailleesMarketAdmin },
            meta: {
                title: 'Weedoit - statistiques Clients WEEDO MARKET',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'codepromo',
            name: 'codes promo LeadMarket Admin',
            components: { default: PromoCodesMarketAdmin },
            meta: {
                title: 'Weedoit - codes promo WEEDO MARKET',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'Stock',
            name: 'Stock LeadMarket Admin',
            components: { default: StockMarketAdmin },
            meta: {
                title: 'Weedoit - Stock WEEDO MARKET',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'Transactions',
            name: 'Transactions LeadMarket Admin',
            components: { default: TransactionsMarketAdmin },
            meta: {
                title: 'Weedoit - Transactions WEEDO MARKET',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'Commandes',
            name: 'Commandes LeadMarket Admin',
            components: { default: CommandesMarketAdmin },
            meta: {
                title: 'Weedoit - Commandes WEEDO MARKET',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'Commandes/:commande_id',
            name: 'Détails Commande LeadMarket Admin',
            components: { default: DetailsCommandesMarketAdmin },
            meta: {
                title: 'Weedoit - Détails de Commande WEEDO MARKET',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'Commandes/edit/:commande_id',
            name: 'edit Commande LeadMarket Admin',
            components: { default: EditCommandesMarketAdmin },
            meta: {
                title: 'Weedoit - édition Commande WEEDO MARKET',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'SuiviRenatbilite',
            name: 'Suivi Renatbilite LeadMarket Admin',
            components: { default: SuiviMonitoringMarketAdmin },
            meta: {
                title: 'Weedoit - Suivi Renatbilite WEEDO MARKET',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'phoneNumberValidation',
            name: 'number phone validation',
            components: { default: PhoneNumberValidation },
            meta: {
                title: 'Weedoit - Validation numéro de téléphone',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'OggoData',
            name: 'Oggo Data LeadMarket Admin',
            components: { default: OggoDataMarketAdmin },
            meta: {
                title: 'Weedoit - Oggo Data WEEDO MARKET',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'leadsDevalides',
            name: 'Devalidation des leads LeadMarket Admin',
            components: { default: leadsDevalidesMarketAdmin },
            meta: {
                title: 'Weedoit - Devalidation des leads WEEDO MARKET',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'surveillanceLeads_2',
            name: 'Surveillance Achat & Vente LeadMarket Admin',
            components: { default: surveillanceLeadsMarketAdmin },
            meta: {
                title: 'Weedoit - Surveillance Achat & Vente WEEDO MARKET',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'surveillanceLeads',
            name: 'Surveillance Achat & Vente LeadMarket Admin 2',
            components: { default: surveillanceLeadsMarketAdmin_2 },
            meta: {
                title: 'Weedoit - Surveillance Achat & Vente WEEDO MARKET 2',
                middleware: [
                    authadmin
                ]
            }
        },
    ]
}

// Weedo Data Admin
let DataAdmin = {
    path: '/weedodata/admin',
    component: DashboardLayoutDataAdmin,
    name: '',
    children: [{
            path: 'dashboard',
            name: 'Home Weedo Data Admin',
            components: { default: Home_WeedoData },
            meta: {
                title: "Weedoit - Tableau de bord Weedo Data",
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'vuestock',
            name: 'Vue Stock Weedo Data Admin',
            components: { default: VueStockDataAdmin },
            meta: {
                title: 'Weedoit - Vue Stock Weedo Data',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'statsDésabonnes',
            name: 'Statistiques de désabonnement',
            components: { default: UnsubscriberStats },
            meta: {
                title: 'Weedoit - Vue Stock Weedo Data',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'VerifEmail',
            name: 'Vue Stock Weedo Data Admin',
            components: { default: VerifyEmailDataAdmin },
            meta: {
                title: 'Weedoit - Verification contact Weedo Data',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'VerifEmailConfirmation/:email/:source',
            name: 'VerifEmailConfirmation',
            components: { default: VerifyEmailDataAdminConfirm },
            meta: {
                title: 'Weedoit - Verification contact Weedo Data',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'contactsDesabonnes',
            name: 'Leads Desabonners',
            components: { default: LeadDesabonnerDataAdmin },
            meta: {
                title: 'Weedoit - Les Contacts Désabonnés',
                middleware: [
                    authadmin
                ]
            }
        },
    ]
}

// Weedo Check Admin
let WeedoCheckAdmin = {
  path: '/weedocheck/admin',
  component: ScenariosLayoutWeedoCheckAdmin,
  name: '',
  children: [{
      path: 'scenarios',
      name: 'Scenarios WeedoCheck Admin',
      components: { default: ScenariosWeedoCheckAdmin },
      meta: {
          title: 'Weedoit - Scenarios WeedoCheck',
          middleware: [
              authadmin
          ]
      }
    },
    {
      path: 'events',
      name: 'Events WeedoCheck Admin',
      components: { default: EventsWeedoCheckAdmin },
      meta: {
          title: 'Weedoit - Events WeedoCheck',
          middleware: [
              authadmin
          ]
      }
    }
  ]
}

/* ************************************         Editeur       ************************************    */
// My Affil Editeur
let AffilEdit = {
    path: '/myaffil/editeur',
    component: DashboardLayoutMyaffilEdit,
    name: '',
    children: [{
            path: 'dashboard',
            name: 'Dashboard MyAffil Editeur',
            components: { default: DashboardMyAffilEdit },
            meta: {
                title: "Weedoit - Tableau de bord PERF",
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'prgramslist',
            name: 'liste programmes My Affil Editeur',
            components: { default: ProgramsListMyAffilEdit },
            meta: {
                title: "Weedoit - Liste des Programmes PERF",
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'viewprogram',
            name: 'Visualiser programme My Affil Editeur',
            components: { default: ProgramVisualierMyAffilEdit },
            meta: {
                title: "Weedoit - Visualiser Programme PERF",
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'MesShoots',
            name: 'Liste Shoot My Affil Editeur',
            components: { default: ListeShootsMyAffilEditeur },
            meta: {
                title: "Weedoit - Liste Shoot PERF",
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'campagnelist',
            name: 'liste campagne My Affil Editeur',
            components: { default: CampagnesListMyAffilEdit },
            meta: {
                title: "Weedoit - Liste des Campagnes PERF",
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'supportslist',
            name: 'liste supports My Affil Editeur',
            components: { default: SupportsListMyAffilEdit },
            meta: {
                title: "Weedoit - Liste des Supports",
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'visuel',
            name: 'liste campagne visuel My Affil Editeur',
            components: { default: CampagnesVisuelMyAffilEdit },
            meta: {
                title: "Weedoit - Visuel PERF",
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'StatsShoots',
            name: 'Stats Shoots My Affil Editeur',
            components: { default: StatsShootsMyAffilEdit },
            meta: {
                title: "Weedoit - Stats Shoots PERF",
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'stats',
            components: { default: StatsLayoutMyAffilEdit },
            children: [{
                path: 'statsglobales',
                name: 'Stats Globales MyAffil Editeur',
                components: { default: StatsGlobalesMyAffilEdit },
                meta: {
                    title: "Weedoit - Stats Globales PERF",
                    middleware: [
                        auth
                    ]
                }
            },
            {
                path: 'statscampagnes',
                name: 'Stats Campagnes MyAffil Editeur',
                components: { default: StatsCampagnesMyAffilEdit },
                meta: {
                    title: "Weedoit - Stats Campagnes PERF",
                    middleware: [
                        auth
                    ]
                }
            },]
        },
        {
            path: 'DetailLeads',
            name: 'stats Detail Leads day for Editeur',
            components: { default: DetailLeadsForEditeur },
            meta: {
                title: "Weedoit - Stats Leads PERF",
                middleware: [
                    auth
                ]
            }
        },
    ]
};
// My Coreg Editeur
let CoregEdit = {
    path: '/mycoreg/editeur',
    component: DashboardLayoutMyCoregEdit,
    name: '',
    children: [{
        path: '',
        name: 'Dashboard MyCoreg Editeur',
        components: { default: DashboardMyCoregEdit },
        meta: {
            middleware: [
                auth
            ]
        }
    }]
};
// My Coreg Editeur
let DataEdit = {
    path: '/datadeal/editeur',
    component: DashboardLayoutDatagEdit,
    name: '',
    children: [{
        path: '',
        name: 'Dashboard Datadeal Editeur',
        components: { default: DashboardDataEdit },
        meta: {
            middleware: [
                auth
            ]
        }
    }]
};

// Weedo Check Editeur
let WeedoCheckEditeur = {
  path: '/weedocheck/editeur',
  component: ScenariosLayoutWeedoCheckEdit,
  name: '',
  children: [{
      path: 'scenarios',
      name: 'Scenarios WeedoCheck Editeur',
      components: { default: ScenariosWeedoCheckEdit },
      meta: {
          title: 'Weedoit - Scenarios WeedoCheck',
          middleware: [
            weedocheck
          ]
      }
    },
    {
      path: 'events',
      name: 'Events WeedoCheck Editeur',
      components: { default: EventsWeedoCheckEdit},
      meta: {
          title: 'Weedoit - Events WeedoCheck',
          middleware: [
            weedocheck
          ]
      }
    }
  ]
};

/* ************************************         Annonceur       ************************************    */
// My Affil Annonceur
let AffilAnnonceur = {
    path: '/myaffil/annonceur',
    component: DashboardLayoutMyaffilAnnonceur,
    name: '',
    children: [{
            path: 'dashboard',
            name: 'Dashboard MyAffil Annonceur',
            components: { default: DashboardMyAffilAnnonceur },
            meta: {
                title: "Weedoit - Tableau de bord PERF",
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'prgramslist',
            name: 'liste programmes My Affil Annonceur',
            components: { default: ProgramsListMyAffilAnnonceur },
            meta: {
                title: "Weedoit - Liste des Programmes PERF",
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'viewprogram',
            name: 'Visualiser programme My Affil Annonceur',
            components: { default: ProgramVisualierMyAffilAnnonceur },
            meta: {
                title: "Weedoit - Visualiser Programme PERF",
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'stats',
            components: { default: StatsLayoutMyAffilAnnonceur },
            children: [{
                path: 'statsglobales',
                name: 'Stats Globales MyAffil Annonceur',
                components: { default: StatsGlobalesMyAffilAnnonceur },
                meta: {
                    title: "Weedoit - Stats Globales PERF",
                    middleware: [
                        auth
                    ]
                }
            },
            {
                path: 'statsdetaillees',
                name: 'Stats détaillées MyAffil Annonceur',
                components: { default: StatsDetailsMyAffilAnnonceur },
                meta: {
                    title: "Weedoit - Stats Détaillées PERF",
                    middleware: [
                        auth
                    ]
                }
            },]
        },
        {
            path: 'MesShoots',
            name: 'Liste Shoot My Affil Annonceur',
            components: { default: ListeShootsMyAffilAnnonceur },
            meta: {
                title: "Weedoit - Liste Shoot PERF",
                middleware: [
                    auth
                ]
            }
        }
    ]
};
// MyMarket Annonceur
let MarketAnnonceur = {
    path: '/weedomarket/annonceur',
    component: DashboardLayoutMarketAnnonceur,
    name: '',
    children: [
        {
            path: 'dashboard',
            name: 'Dashboard Leadmarket Annonceur',
            components: { default: DashboardMarketAnnonceur },
            meta: {
                title: 'Weedoit - Tableau de bord WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'mesLeads',
            name: 'mes Leads Leadmarket Annonceur',
            components: { default: MyLeadMarketAnnonceur },
            meta: {
                title: 'Weedoit - Mes leads WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'mesCommandes',
            name: 'mes Commandes Leadmarket Annonceur',
            components: { default: MyCommandeMarketAnnonceur },
            meta: {
                title: 'Weedoit - Mes commandes WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'mesfiltres',
            name: 'mes filtres Leadmarket Annonceur',
            components: { default: MyFiltersMarketAnnonceur },
            meta: {
                title: 'Weedoit - Mes filtres WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'creeCommande/:commande_id?',
            name: 'cree Commandes Leadmarket Annonceur',
            components: { default: AddCommandeMarketAnnonceur },
            meta: {
                title: 'Weedoit - Création de commandes WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'creefiltre',
            name: 'cree filtres Leadmarket Annonceur',
            components: { default: AddFilterMarketAnnonceur },
            meta: {
                title: 'Weedoit - Création de filtres WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'editerfiltre/:id_filter',
            name: 'editer filtres Leadmarket Annonceur',
            components: { default: EditFilterMarketAnnonceur },
            meta: {
                title: 'Weedoit - Modification de filtres WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'mes-prepaiement',
            name: 'mes Prepayments Leadmarket Annonceur',
            components: { default: MyPrepaymentsAnnonceur },
            meta: {
                title: 'Weedoit - Mes Prepayments WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'mesTarifs',
            name: 'Mes Tarifs Leadmarket Annonceur',
            components: { default: MyTarifsAnnonceur },
            meta: {
                title: 'Weedoit - Mes Tarifs WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'partenaires',
            name: 'Partners Leadmarket Annonceur',
            components: { default: HomePartnersMarketAnnonceur },
            meta: {
                title: 'Weedoit - Partenaires WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'partenaires/ecilia',
            name: 'ecilia Partners Leadmarket Annonceur',
            components: { default: EciliaPartnersMarketAnnonceur },
            meta: {
                title: 'Weedoit - Partenaires WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'partenaires/oggodata',
            name: 'oggodata Partners Leadmarket Annonceur',
            components: { default: OggodataPartnersMarketAnnonceur },
            meta: {
                title: 'Weedoit - Partenaires WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'partenaires/courtigo',
            name: 'courtigo Partners Leadmarket Annonceur',
            components: { default: CourtigoPartnersMarketAnnonceur },
            meta: {
                title: 'Weedoit - Partenaires WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'partenaires/So-Soft',
            name: 'So Soft Partners Leadmarket Annonceur',
            components: { default: SoSoftPartnersMarketAnnonceur },
            meta: {
                title: 'Weedoit - Partenaires WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'partenaires/insquary',
            name: 'insquary Partners Leadmarket Annonceur',
            components: { default: InsquaryPartnersMarketAnnonceur },
            meta: {
                title: 'Weedoit - Partenaires WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'partenaires/ernest',
            name: 'ernest Partners Leadmarket Annonceur',
            components: { default: ErnestPartnersMarketAnnonceur },
            meta: {
                title: 'Weedoit - Partenaires WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'partenaires/bubbleIn',
            name: 'bubbleIn Partners Leadmarket Annonceur',
            components: { default: BubbleInPartnersMarketAnnonceur },
            meta: {
                title: 'Weedoit - Partenaires WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'partenaires/ringover',
            name: 'ringover Partners Leadmarket Annonceur',
            components: { default: RingoverPartnersMarketAnnonceur },
            meta: {
                title: 'Weedoit - Partenaires WEEDO MARKET',
                middleware: [
                    auth
                ]
            }
        },
    ]
};

// Mongustave Annonceur
let MongustaveAnnonceur = {
    path: '/mongustave/annonceur',
    component: DashboardLayoutMongustaveAnnonceur,
    name: '',
    name: '',
    children: [
        {
            path: 'dashboard',
            name: 'Dashboard Mon Gustave Annoncer',
            components: { default: Dashboard_mongustave_an },
            meta: {
                title: "Weedoit - Tableau de bord mongustave",
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'statistiques',
            name: 'Stats Clients Mon Gustave Annoncer',
            components: { default: StatGustaveClientsMarketAdmin },
            meta: {
                title: 'Weedoit - Statistiques Mon Gustave',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: 'extract-mer',
            name: 'mer extract Mon Gustave Annoncer',
            components: { default: ExtractMerMgAnnonceur },
            meta: {
                title: 'Weedoit - mer extract Mon Gustave',
                middleware: [
                    auth
                ]
            }
        }
    ]
}

// Weedo Check Annonceur
let WeedoCheckAnnonceur = {
  path: '/weedocheck/annonceur',
  component: ScenariosLayoutWeedoCheckAnnonceur,
  name: '',
  children: [{
      path: 'scenarios',
      name: 'Scenarios WeedoCheck Annonceur',
      components: { default: ScenariosWeedoCheckAnnonceur },
      meta: {
          title: 'Weedoit - Scenarios WeedoCheck',
          middleware: [
            weedocheck
          ]
      }
    },
    {
      path: 'events',
      name: 'Events WeedoCheck Annonceur',
      components: { default: EventsWeedoCheckAnnonceur},
      meta: {
          title: 'Weedoit - Events WeedoCheck',
          middleware: [
            weedocheck
          ]
      }
    }
  ]
};

/******************************************  reel time stats ***************************************/
let ReelTimeStats = {
    path: '/temps-reel',
    component: LayoutReelTimeStats,
    name: '',
    children: [{
            path: 'mongustave',
            name: 'Stats Global Mon Gustave Admin real time',
            components: { default: StatGustaveGlobalMarketAdmin_RealTime },
            meta: {
                title: 'Weedoit - Statistiques Mon Gustave temp reel',
                middleware: [
                    authadmin
                ]
            }
        },
        {
            path: 'weedomarket',
            name: 'Stats Global weedomarket Admin real time',
            components: { default: StatWeedoMarketAdmin_RealTime },
            meta: {
                title: 'Weedoit - Statistiques weedomarket temp reel',
                middleware: [
                    authadmin
                ]
            }
        },
    ]
}

const routes = [{
        path: '/',
        redirect: '/dashboard',
        name: 'Home',
        meta: {
          title: "Weedoit - Tableau de bord",
            middleware: [
                authadmin
            ]
        }
    },
    {
        path: '/apercu_template/:idf/:id_visuel/:campagne/:hostname',
        name: 'apercu_template',
        component: apercu_template
    },
    {
        path: '/checktocken/:token',
        name: 'checktocken2',
        component: checktocken
    },
    {
        path: '/checktocken/*',
        name: 'checktocken',
        meta: {
            middleware: [
                urlFormater
            ]
        }
    },
    pagesMenu,
    authPages,
    AffilAdmin,
    MarketAdmin,
    DataAdmin,
    WeedoCheckAdmin,
    AffilEdit,
    CoregEdit,
    DataEdit,
    WeedoCheckEditeur,
    AffilAnnonceur,
    MarketAnnonceur,
    MongustaveAnnonceur,
    WeedoCheckAnnonceur,
    bedrockInterface,
    ReelTimeStats,
    {
        path: '/',
        component: GlobalLayout,
        name: 'Globale layout',
        children: [
            {
                path: '/report/rapport',
                name: 'liste des Rapports',
                component: Rapport,
                meta: {
                    title: "Weedoit - Liste des Rapports PERF",
                    middleware: [
                        authadmin
                    ]
                }
            },
            /* {
                path: '/report/CaClient',
                name: 'repport ca clients by quarter',
                component: RapportCa_Client,
                meta: {
                    title: "Weedoit - CA Client par Trimestre",
                    middleware: [
                        authadmin
                    ]
                }
            }, */
            {
                path: '/report/AvancesClient/:client/:platform/:id_facture_perf/:id_facture_coreg/:id_perf/:id_coreg',
                name: 'liste des Avances Client',
                component: Advance_Client,
                meta: {
                    title: "Weedoit - Avances Client",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: '/report/rapportCoreg',
                name: 'liste des Rapports Coreg',
                component: RapportCoreg,
                meta: {
                    title: "Weedoit - Liste des Rapports COREG",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: '/report/rapportMongustave',
                name: 'liste des Rapports Mon Gustave',
                component: RapportMongustave,
                meta: {
                    title: "Weedoit - Liste des Rapports Mon Gustave",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: '/report/payments',
                name: 'liste des Paiments Global',
                component: Payment,
                meta: {
                    title: "Weedoit - Liste des Paiments",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: '/report/factures',
                name: 'liste des Factures Global',
                component: Facture,
                meta: {
                    title: "Weedoit - Liste des Factures",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: '/report/facturesMonGustave',
                name: 'liste des Factures Mongustave',
                component: FactureMongustave,
                meta: {
                    title: "Weedoit - Liste des Factures Mon Gustave",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: '/report/facturesLeadMarket',
                name: 'liste des Factures LeadMarket',
                components: { default: ComptabiliteMarketAdmin },
                meta: {
                    title: 'Weedoit - liste des Factures LeadMarket',
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
              path: '/report/facturesAvoir',
              name: 'liste des Factures Avoir',
              components: { default: FacturesAvoir },
              meta: {
                  title: 'Weedoit - liste des Factures Avoir',
                  middleware: [
                      authadmin
                  ]
              }
            },
            {
                path: '/report/facturesWeedoMarket',
                name: 'liste des Factures Weedo Market',
                component: FactureWeedoMarket,
                meta: {
                    title: "Weedoit - Liste des Factures Weedo Market",
                    middleware: [
                        authadmin
                    ]
                }
            },
        ]
    },
    {
        path: '/',
        component: DashboardLayout,
        redirect: '/dashboard',
        name: 'Dashboard layout',
        children: [{
                path: 'dashboard',
                name: 'ADMIN Dashboard',
                components: { default: Dashboard_admin },
                meta: {
                    title: "Weedoit - Tableau de bord",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: '/report',
                name: 'Globale Reports',
                component: Report,
                meta: {
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: 'mes-documents-oi',
                name: 'liste des OI disponible Annonceur',
                components: { default: ListeOIdisponibleAnnonceur },
                meta: {
                    title: "Weedoit - Liste des OI",
                    middleware: [
                        auth
                    ]
                }
            },
            {
                path: 'stats-valides',
                name: 'stats-valides',
                redirect: "stats-valides/stats-valides-myaffil",
                components: { default: StatsValides },
                children: [
                    {
                        path: 'stats-valides-myaffil',
                        name: 'stats-valides-myaffil',
                        components: { default: StatsValidesMyAffil },
                        meta: {
                            title: "Weedoit - Stats Valides PERF",
                            middleware: [
                                auth
                            ]
                        }
                    },
                    {
                        path: 'stats-valides-mycoreg',
                        name: 'stats-valides-mycoreg',
                        components: { default: StatsValidesMyCoreg },
                        meta: {
                            title: "Weedoit - Stats Valides COREG",
                            middleware: [
                                auth
                            ]
                        }
                    }
                ],
            },
            {
                path: 'mes-prepaiement',
                name: 'prepaiement',
                components: { default: new_paiementv2 },
                meta: {
                    title: "Weedoit - Mes Prepaiement",
                    middleware: [
                        auth
                    ]
                }
            },
            {
                path: 'mes-prepaiement-v2',
                name: 'prepaiement v2',
                components: { default: new_paiementv2 },
                meta: {
                    title: "Weedoit - Mes Prepaiement v2",
                    middleware: [
                        auth
                    ]
                }
            },
            {
                path: 'New_mes-prepaiement',
                name: 'prepaiement',
                components: { default: paiement },
                meta: {
                    title: "Weedoit - Mes Prepaiement New",
                    middleware: [
                        auth
                    ]
                }
            },
            {
                path: 'AppelFactureEditeur',
                name: 'Appel a Facture Editeur',
                components: { default: AppelaFactureEdit },
                meta: {
                    title: "Weedoit - Appel a Facture",
                    middleware: [
                        auth
                    ]
                }
            },
            {
                path: 'FactureAnnonceur',
                name: 'Facture Annonceur',
                components: { default: FactureAnnonceur_NEW },
                meta: {
                    title: "Weedoit - Factures",
                    middleware: [
                        auth
                    ]
                }
            },
            {
                path: 'New_FactureAnnonceur',
                name: 'Facture Annonceur NEW',
                components: { default: FactureAnnonceur },
                meta: {
                    title: "Weedoit - Factures NEW",
                    middleware: [
                        auth
                    ]
                }
            },
            {
                path: 'OIlist/:id_annonceur?',
                name: 'liste de OI disponible Admin',
                components: { default: ListeOIdisponible },
                meta: {
                    title: "Weedoit - Liste des OI disponible",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: 'TeletechLeads',
                name: 'suivi leads Teletech',
                components: { default: suiviTeletechLeads },
                meta: {
                    title: "Weedoit - les leads Teletech",
                    middleware: [
                        auth
                    ]
                }
            },
            {
                path: 'add_oi/:id_utilisateur/:id_annonceur?',
                name: 'add_oi',
                components: { default: AddOI },
                meta: {
                    title: "Weedoit - Ajouter un OI",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: 'updateOi',
                name: 'update Oi',
                components: { default: UpdateOi },
                meta: {
                    title: "Weedoit - Modifier un OI",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: 'ListePresPaiements',
                name: 'liste pre-paiement admin',
                components: { default: ListPrePayment },
                meta: {
                    title: "Weedoit - Liste Pre-paiement",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: 'statsSocialMedia',
                name: 'Stats CA par leviers',
                components: { default: StatsCaParLeviers },
                meta: {
                    title: "Weedoit - Stats par Leviers",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: 'Logs',
                name: 'Logs Admin',
                components: { default: LogsAdmin },
                meta: {
                    title: 'Weedoit - Logs',
                    middleware: [
                        authadmin
                    ]
                }
            },
{
              path: 'pushNotifications',
              name: 'Notification Push',
              redirect: "pushNotifications/statistiques",
              components: { default: PushNotifications },
              children: [
                    {
                        path: 'statistiques',
                        name: 'Statistiques Push Notifications',
                        components: { default: StatsPushNotifications },
                        meta: {
                            title: "Weedoit - Stats Push Notifications",
                            middleware: [
                              authadmin
                            ]
                        }
                    },
                    {
                        path: 'logs',
                        name: 'Logs Push Notifications',
                        components: { default: LogsPushNotifications },
                        meta: {
                            title: "Weedoit - Logs Push Notifications",
                            middleware: [
                              authadmin
                            ]
                        }
                    }
                ],
            },
            {
                path: 'Membreslist',
                name: 'liste membres Admin',
                components: { default: ListeMembers },
                meta: {
                    title: "Weedoit - Liste des Membres",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: 'ltv',
                name: 'liste des test',
                components: { default: ListeTest },
                meta: {
                   title: "Weedoit - LTV ",
                    middleware: [
                        authadmin
                    ]
                }
           },

            {
              path: '/report/CaClient',
              name: 'repport ca clients by quarter',
              component: RapportCa_Client,
              meta: {
                  title: "Weedoit - CA Client par Trimestre",
                  middleware: [
                      authadmin
                  ]
              }
            },
            {
                path: 'AppelFactureEditeur/:id_utilisateur/:id_editeur',
                name: 'Appel a Facture Editeur-Admin',
                components: { default: AppelaFactureEdit },
                meta: {
                    title: "Weedoit - Appel a Facture Editeur-Admin",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: 'StatsTopCaAnOrAf',
                name: 'Stats Ca Perf An Af',
                components: { default: StatsTopCaAnOrAfAdmin },
                meta: {
                    title: "Weedoit - Statistiques Ca Annonceur/Editeur",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: 'member/:utilisateur_id?',
                name: 'member',
                components: { default: Member },
                meta: {
                    title: "Weedoit - Membre",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
                path: 'dashboard-editeur',
                name: 'Editeur Dashboard',
                components: { default: Dashboard_editeurs },
                meta: {
                    title: "Weedoit - Tableau de bord",
                    middleware: [
                        auth
                    ]
                }
            },
            {
                path: 'dashboard-annonceur',
                name: 'Annonceur Dashboard',
                components: { default: Dashboard_annonceur },
                meta: {
                    title: "Weedoit - Tableau de bord",
                    middleware: [
                        auth
                    ]
                }
            },
            {
                path: 'DashboardMonGustaveAdmin',
                name: 'Dashboard Mon Gustave Admin',
                components: { default: Dashboard_mongustave_ad },
                meta: {
                    title: "Weedoit - Tableau de bord mongustave admin",
                    middleware: [
                        authadmin
                    ]
                }
            },
            {
        path: 'DashboardCadeaux',
        name: 'Dashboard Mes Cadeaux',
        components: { default: Dashboard_cadeaux },
        meta: {
            title: "Weedoit - Cadeaux",
            middleware: [
                auth
            ]
        }
    },
    {
                path: 'infos-bancaire',
                name: 'infos-bancaire',
                components: { default: InfosBancaire },
                children: [
                    {
                        path: 'affilie-france',
                        name: 'affilie-france',
                        components: { default: AffilieFrance },
                        meta: {
                            title: "Weedoit - Infos Bancaire France",
                            middleware: [
                                auth
                            ]
                        }
                    },
                    {
                        path: 'affilie-international',
                        name: 'affilie-international',
                        components: { default: AffilieInternational },
                        meta: {
                            title: "Weedoit - Infos Bancaire International",
                            middleware: [
                                auth
                            ]
                        }
                    }
                ],
            },
            //  Home Core & DataDeal
            {
                path: 'weedocoreg',
                name: 'Home Weedo Coreg',
                components: { default: Home_WeedoCoreg },
                meta: {
                    title: "Weedoit - Tableau de bord Weedo Coreg",
                    middleware: [
                        auth
                    ]
                }
            },
            {
                path: 'weedodata',
                name: 'Home Weedo Data',
                components: { default: Home_WeedoData },
                meta: {
                    title: "Weedoit - Tableau de bord Weedo Data",
                    middleware: [
                        auth
                    ]
                }
            },
        ]
    },
    { path: '*', component: NotFound }
];

export default routes;
