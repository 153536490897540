import Vue from 'vue'
import Vuex from 'vuex'
import { config } from 'src/cognito_conf.js'
import VueJwtDecode from 'vue-jwt-decode'

Vue.use(Vuex)

var AMAZON_IDENTITY = require('amazon-cognito-identity-js')

export default new Vuex.Store({
    state: {
        email: '',
        apierreur: '',
        utilisateur: null,
        endregistration: false,
        checkuserExist: false,
        PopUp_Whatsapp_WM: true,
        PopUp_WeedoMax_AF: true,
        LeadsPanier_trio: 0,
        LeadsPanier_duo: 0,
        LeadsPanier_exclusif: 0,
        UT8_array: { "á" : "Ã¡", "À" : "Ã€", "ä" : "Ã¤", "Ä" : "Ã„", "ã" : "Ã£", "å" : "Ã¥", "Å" : "Ã…", "æ" : "Ã¦", "Æ" : "Ã†", "ç" : "Ã§", "Ç" : "Ã‡", "é" : "Ã©", "É" : "Ã‰", "è" : "Ã¨", "È" : "Ãˆ", "ê" : "Ãª", "Ê" : "ÃŠ", "ë" : "Ã«", "Ë" : "Ã‹", "í" : "Ã-­­", "à" : "Ã",  "ì" : "Ã¬", "Ì" : "ÃŒ", "î" : "Ã®", "Î" : "ÃŽ", "ï" : "Ã¯", "à" : "Ã",  "ñ" : "Ã±", "Ñ" : "Ã‘", "ó" : "Ã³", "Ó" : "Ã“", "ò" : "Ã²", "Ò" : "Ã’", "ô" : "Ã´", "Ô" : "Ã”", "ö" : "Ã¶", "Ö" : "Ã–", "õ" : "Ãµ", "Õ" : "Ã•", "ø" : "Ã¸", "Ø" : "Ã˜", "œ" : "Å“", "Œ" : "Å’", "ß" : "ÃŸ", "ú" : "Ãº", "Ú" : "Ãš", "ù" : "Ã¹", "Ù" : "Ã™", "û" : "Ã»", "Û" : "Ã›", "ü" : "Ã¼", "Ü" : "Ãœ", "€" : "â‚¬", "’" : "â€™", "‚" : "â€š", "ƒ" : "Æ’", "„" : "â€ž", "…" : "â€¦", "‡" : "â€¡", "ˆ" : "Ë†", "‰" : "â€°", "Š" : "Å ", "‹" : "â€¹", "Ž" : "Å½", "‘" : "â€˜", "“" : "â€œ", "•" : "â€¢", "–" : "â€“", "—" : "â€”", "˜" : "Ëœ", "™" : "â„¢", "š" : "Å¡", "›" : "â€º", "ž" : "Å¾", "Ÿ" : "Å¸", "¡" : "Â¡", "¢" : "Â¢", "£" : "Â£", "¤" : "Â¤", "¥" : "Â¥", "¦" : "Â¦", "§" : "Â§", "¨" : "Â¨", "©" : "Â©", "ª" : "Âª", "«" : "Â«", "¬" : "Â¬", "®" : "Â®", "¯" : "Â¯", "°" : "Â°", "±" : "Â±", "²" : "Â²", "³" : "Â³", "´" : "Â´", "µ" : "Âµ", "¶" : "Â¶", "·" : "Â·", "¸" : "Â¸", "¹" : "Â¹", "º" : "Âº", "»" : "Â»", "¼" : "Â¼", "½" : "Â½", "¾" : "Â¾", "¿" : "Â¿", "à" : "Ã ", "†" : "â€ ", "”" : "â€", "Á" : "Ã", "â" : "Ã¢", "Â" : "Ã‚", "Ã" : "Ãƒ" }

    },
    mutations: {
        setLeadsPanier_trio(state, LeadsPanier_trio) {
            state.LeadsPanier_trio = LeadsPanier_trio
        },
        setLeadsPanier_duo(state, LeadsPanier_duo) {
            state.LeadsPanier_duo = LeadsPanier_duo
        },
        setLeadsPanier_exclusif(state, LeadsPanier_exclusif) {
            state.LeadsPanier_exclusif = LeadsPanier_exclusif
        },
        setendregistration(state, endregistration) {
            state.endregistration = endregistration
        },
        setcheckuserExist(state, checkuserExist) {
            state.checkuserExist = checkuserExist
        },
        setemail(state, email) {
            state.email = email;
        },
        setapierreur(state, error) {
            state.apierreur = error
        },
        setUtilisateur(state, utilisateur) {
            state.utilisateur = utilisateur
        },
        setPopUp_Whatsapp_WM(state, PopUp_Whatsapp_WM) {
            state.PopUp_Whatsapp_WM = PopUp_Whatsapp_WM
        },
        setPopUp_WeedoMax_AF(state, PopUp_WeedoMax_AF) {
            state.PopUp_WeedoMax_AF = PopUp_WeedoMax_AF
        },
        signOut(state) {
            localStorage.removeItem("token_jwt")
            localStorage.clear()
            var data = {
                UserPoolId: config.cognito.userPoolId,
                ClientId: config.cognito.clientId
            }
            var userPool = new AMAZON_IDENTITY.CognitoUserPool(data)
            var cognitoUser = userPool.getCurrentUser()
            if (cognitoUser != null) {
                cognitoUser.signOut()
            }
        },
    },
    actions: {},
    modules: {},
    getters: {
        auth(state) {
            var token = localStorage.getItem('token_jwt')
            var data = {
                UserPoolId: config.cognito.userPoolId,
                ClientId: config.cognito.clientId
            }
            var userPool = new AMAZON_IDENTITY.CognitoUserPool(data)
            var cognitoUser = userPool.getCurrentUser()
            if (cognitoUser == null && token == null) {
                return false
            } else {
                try {
                    var now = Math.round((new Date()).getTime() / 1000)
                    let decode = VueJwtDecode.decode(token)
                    if (now >= decode.exp) {
                        localStorage.removeItem('token_jwt')
                        localStorage.clear()
                        if (cognitoUser != null) {
                            cognitoUser.signOut()
                        }
                        return false
                    } else {
                        return true
                    }
                } catch (error) {
                    localStorage.removeItem('token_jwt')
                    localStorage.clear()
                    if (cognitoUser != null) {
                        cognitoUser.signOut()
                    }
                    return false
                }
            }
        },
        getemail: state => {
            return state.email
        },
        getapierreur: state => {
            return state.apierreur
        },
        getUtilisateur: state => {
            return state.utilisateur
        },
        getendregistration: state => {
            return state.endregistration
        },
        getcheckuserExist: state => {
            return state.checkuserExist
        },
        getPopUp_Whatsapp_WM: state => {
            return state.PopUp_Whatsapp_WM
        },
        getPopUp_WeedoMax_AF: state => {
            return state.PopUp_WeedoMax_AF
        }
    }
})