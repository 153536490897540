var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{staticStyle:{"background":"linear-gradient(100deg, #fb6340, #ff8d72)"},attrs:{"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('SidebarMenu.Dashboard'),
          icon: 'fas fa-desktop',
          path: '/weedodata/admin/dashboard'
        }}}),_c('sidebar-item',{attrs:{"link":{
           name: 'Vue sur le stock',
          icon: 'fa fa-clipboard',
          path: '/weedodata/admin/vuestock'
        }}}),_c('sidebar-item',{attrs:{"link":{
           name: 'vue sur Désabonnés ',
          icon: 'fa-solid fa-users-slash',
          path: '/weedodata/admin/statsDésabonnes'
        }}}),_c('sidebar-item',{attrs:{"link":{
           name: 'Recherche Contact',
          icon: 'fas fa-user-check',
          path: '/weedodata/admin/VerifEmail'
        }}}),_c('sidebar-item',{attrs:{"link":{
           name: 'Contacts Désabonnés',
          icon: 'fas fa-user-xmark',
          path: '/weedodata/admin/contactsDesabonnes'
        }}})]}}])},[_c('div',{staticClass:"logo"},[_c('a',{staticClass:"simple-text logo-mini",attrs:{"href":""}},[_c('img',{attrs:{"src":_vm.logo,"alt":"app-logo"}})]),_c('a',{staticClass:"simple-text logo-normal",attrs:{"href":""}},[_vm._v("\n        WEEDO DATA\n      ")])]),_vm._t("default")],2),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":"blue"}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }