<template>
  <div>

    <li
      v-if='Nb_Payment_Waiting'
      @click="GoToPayment()"
      class="li-notif"
    >
      <i class="tim-icons icon-money-coins"></i>&nbsp;
      <label>
        VOTRE APPEL À FACTURE EST DISPONIBLE
      </label>
    </li>
    
    <li
      v-if='Nb_CampagneValide'
      @click="GoToCampagne()"
      class="li-notif"
    >
      <i class="tim-icons icon-align-center"></i>&nbsp;
      <label>
        VOUS AVEZ <b>{{ Nb_CampagneValide }}</b> CAMPAGNE<template v-if="Nb_CampagneValide!=1">S</template> ACCEPTÉE<template v-if="Nb_CampagneValide!=1">S</template>
      </label>
    </li>

    <li
      v-if='!Nb_CampagneValide && !Nb_Payment_Waiting'
      class="li-notif"
    >
      <h5>
        VOUS N'AVEZ AUCUNE NOTIFICATION
      </h5>
    </li>

  </div>
</template>

<script>
  import gql from 'graphql-tag'

  export default {
    watch: {
      totalnotif(value) {
        this.$emit('nb_notifAf', value);
      }
    },
    computed: {
      totalnotif() {
        let total = 0
        total = this.Nb_Payment_Waiting + this.Nb_CampagneValide
        return total
      }
    },
    apollo: {
      Nb_Payment_Waiting: {
        client: 'AUTH',
        query: gql`
          query  {
            getPaiementAttentAf(id_client_perf: 10,id_client_coreg: 10){
              nb
            }
          }
        `,
        fetchPolicy: 'no-cache',
        variables: {
          id_client_perf: (JSON.parse(localStorage.getItem('utilisateur')) != null) ? JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil : null,
          id_client_coreg: (JSON.parse(localStorage.getItem('utilisateur')) != null) ? JSON.parse(localStorage.getItem('utilisateur')).id_clien_mycoreg : null
        },
        update: data => data.getPaiementAttentAf.nb
      },
      Nb_CampagneValide: {
        client: 'AUTH',
        query: gql`
          query ($id_client_perf: Int) {
            getCampagneValideAf(id_client_perf: $id_client_perf){
              nb
            }
          }
        `,
        fetchPolicy: 'no-cache',
        variables: {
          id_client_perf: (JSON.parse(localStorage.getItem('utilisateur')) != null) ? JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil : null
        },
        update: data => data.getCampagneValideAf.nb
      },
    },
    methods: {
      GoToPayment() {
        if (this.$router.history.current.name != "Appel a Facture Editeur")
          this.$router.push({name: "Appel a Facture Editeur"})
      },
      GoToCampagne() {
        if (this.$router.history.current.name != "liste campagne My Affil Editeur")
          this.$router.push({name: "liste campagne My Affil Editeur"})
      }
    },
  }

</script>

<style scoped>
  .li-notif {
    margin-top: -1px; /* Prevent double borders */
    color: white;
  }
  .li-notif:hover {
    color: black;
  }
  .li-notif label {
    font-size: small;
    cursor: pointer;
  }

  .li-notif i {
    cursor: pointer;
  }
</style>