var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{staticStyle:{"background":"linear-gradient(0deg, #2b3277 0%, #588bbd 100%)"},attrs:{"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('SidebarMenu.Dashboard'),
          icon: 'tim-icons icon-tv-2',
          path: '/weedomarket/annonceur/dashboard'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Mon suivi qualité',
          icon: 'tim-icons icon-badge',
          path: '/weedomarket/annonceur/mesLeads'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Mes Commandes',
          icon: 'tim-icons icon-bag-16',
          path: '/weedomarket/annonceur/mesCommandes'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'MES FILTRES',
          icon: 'fas fa-filter',
          path: '/weedomarket/annonceur/mesfiltres'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'PRÉPAIEMENT',
          icon: 'tim-icons icon-money-coins',
          path: '/mes-prepaiement'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Mes Tarifs',
          icon: 'fas fa-tags',
          path: '/weedomarket/annonceur/mesTarifs'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'PARTENAIRES',
          icon: 'far fa-handshake',
          path: '/weedomarket/annonceur/partenaires'
        }}})]}}])},[_c('div',{staticClass:"logo"},[_c('a',{staticClass:"simple-text logo-mini",attrs:{"href":""}},[_c('img',{attrs:{"src":_vm.logo,"alt":"app-logo"}})]),_c('a',{staticClass:"simple-text logo-normal",attrs:{"href":""}},[_vm._v("\n        Weedo market\n      ")])]),_vm._t("default")],2),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":"blue"}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }