let config

config = {
  cognito: {
    userPoolId: 'eu-central-1_CrCefP7v3',
    region: 'eu-central-1',
    clientId: '2lc6tns5ehl25dqu4dp4gmb451'
  }
}

export {
  config
}
