// Validation plugin used to validate forms
import VeeValidate, { Validator } from 'vee-validate';

import fr from "vee-validate/dist/locale/fr";
import en from "vee-validate/dist/locale/en";

if (localStorage.getItem('lang') == "en") {
    Validator.localize({ en: en });
}
else {
    Validator.localize({ fr: fr });
}
// ------------------------------------ password validator -------------------------------------- //
VeeValidate.Validator.extend('password', {
    validate: value => {
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
        return strongRegex.test(value);
    }
});

// ------------------------------------ verification code validator -------------------------------------- //
VeeValidate.Validator.extend('verificationCode', {
  validate: value => {
      var strongRegex = new RegExp("^[0-9]{6}$");
      return strongRegex.test(value);
  }
});

Validator.localize('fr', {
    messages: {
        password: 'Votre mot de passe doit comporter au 8 caractères. Il doit impérativement se composer de 4 types : Majuscules, minuscules, chiffres et caractères spéciaux.',
        verificationCode: 'Le code de vérification doit contenir 6 chiffres.',
    }
});

Validator.localize('en', {
    messages: {
        password: 'Your password must be 8 characters long. It must be composed of 4 types: Upper case, lower case, numbers and special characters.',
        verificationCode: 'Verification code must contain 6 digits.',
    }
});

// ---------------------------------- Support name validator -------------------------------------- //
VeeValidate.Validator.extend('supportname', {
    validate: value => {
        var strongRegex = new RegExp("^[a-zA-Z0-9 -]*$");
        return strongRegex.test(value);
    }
});
Validator.localize('fr', {
    messages: {
        supportname: 'Le champ support ne doit pas contenir des caractères spéciaux'
    }
});
Validator.localize('en', {
    messages: {
        supportname: 'The support field must not contain special characters'
    }
});

export {
    VeeValidate
}
