export default function urlFormater({ next, store }) {
    if (window.location.href.includes('#access_token')) {
        var url = window.location.href.split('#')[1]
        var v = url.split('&')[1]
        var token = v.split('=')[1]
        return next({
            path: 'checktocken/' + token
        })
    }
    return next({
        name: 'Login'
    })
}