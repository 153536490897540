<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper" id="TOPPAGE">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <!-- <a class="navbar-brand">{{ routeName }}</a> -->
        <base-button
          v-if="showhome"
          @click.native="$router.push('/');"
          class="edit btn-link"
          size="sm"
          icon
          type="info"
        >
          <i class="fas fa-home"></i>
        </base-button>
      <router-link to='' class="navbar-brand">WEEDO IT</router-link>
      <label>Bêta</label>
      <!-- switsh role af to an -->
      <template v-if="hasMultiLaw">
        <button class="btn btn-neutral btn-simple fontsizeLawS" :disabled="disabledSwitch"  v-if="CurrentLaw == 'an'" @click="SwitchToEditeur">
          <i class="fas fa-exchange-alt"></i>  PASSER EN MODE EDITEUR
        </button>
        <button class="btn btn-neutral btn-simple fontsizeLawS" :disabled="disabledSwitch" v-if="CurrentLaw == 'af'" @click="SwitchToAnnonceur">
          <i class="fas fa-exchange-alt"></i>  PASSER EN MODE ANNONCEUR
        </button>
      </template>
      <template v-else>
        <template v-if="id_clien_leadmarket === 0">
          <template v-if="CurrentLaw == 'an' || CurrentLaw == 'af'">
            <button class="btn btn-neutral btn-simple fontsizeLawS"  @click="$router.push({ name: 'User Profile' })">
              <i class="fas fa-exchange-alt"></i> DEVENIR {{ (CurrentLaw == 'af') ? 'ANNONCEUR' : 'EDITEUR' }}
            </button>
          </template>
        </template>
      </template>
    </div>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <!-- <div class="search-bar input-group" @click="searchModalVisible = true"> -->
        <!--
          <input type="text" class="form-control" placeholder="Search...">
          <div class="input-group-addon"><i class="tim-icons icon-zoom-split"></i></div>
        -->
        <!-- <button
          class="btn btn-link"
          id="search-button"
          data-toggle="modal"
          data-target="#searchModal"
        >
          <i class="tim-icons icon-zoom-split"></i>
        </button> -->
        <!-- You can choose types of search input -->
      <!-- </div> -->
      <modal
        :show.sync="searchModalVisible"
        class="modal-search"
        id="searchModal"
        :centered="false"
        :show-close="true"
      >
        <input
          slot="header"
          v-model="searchQuery"
          type="text"
          class="form-control"
          id="inlineFormInputGroup"
          placeholder="CHERCHER"
        />
      </modal>
      <label
        class="whitcontent ml-2 d-none d-lg-block d-xl-block"
        v-if="this.lawForCadeau"
      >
        Vos points Weedo MAX <br />
        <a style="cursor: pointer">
          <span
            @click="generateUrlCadaux"
            class="soldcadeau"
            style="color: #ec277a; font-weight: bold"
            >{{ this.soldeCadeau }} Points accumulés</span
          >
        </a>
      </label>
      <base-button
        size="sm"
        :type="(nb_notifAdm || nb_notifAn || nb_notifAf) ? 'notif' : 'transparent'"
        style="border-radius: 5px"
        @click="ShowNotif = true"
      >
        <div class="notification"></div>
        <i class="far fa-bell whitcontent" style="vertical-align: middle;font-size: 22px; width: 35px;"></i>
      </base-button>
      <label class="whitcontent ml-2 d-none d-lg-block d-xl-block">
        {{ $t('labels.Welcome') }} <u>{{ replaceUTF_8(First_name) }} {{ (Last_name) ? replaceUTF_8(Last_name).toUpperCase() : Last_name }} </u> <br>
        {{ $t('table.columns.Society') }}  : {{ (society) ? replaceUTF_8(society).toUpperCase() : society }}
      </label>
      <label class="d-lg-none">
        {{ $t('labels.Welcome') }} <u>{{ replaceUTF_8(First_name) }} {{ (Last_name) ? replaceUTF_8(Last_name).toUpperCase() : Last_name }} </u><br>
        {{ $t('table.columns.Society') }}  : {{ (society) ? replaceUTF_8(society).toUpperCase() : society }}
      </label>
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
      >
        <template
          slot="title"
        >
          <div class="photo"><img :src="imageUrl" /></div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none">menu</p>
        </template>
        <li class="nav-link">
          <router-link :to="{ name: 'User Profile' }">
            <a href="" class="nav-item dropdown-item">
              <i class="tim-icons icon-trophy"></i>
              {{ $t("labels.Profile") }}
            </a>
          </router-link>
        </li>
        <li class="nav-link">
          <!-- <a href="#" class="nav-item dropdown-item">
            <i class="tim-icons icon-settings-gear-63"></i>
            Settings
          </a> -->
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a @click="signOut" style="cursor: pointer;" class="nav-item dropdown-item">
            <i class="tim-icons icon-settings-gear-63"></i>
            {{ $t("buttons.signOut") }}
          </a>
        </li>
      </base-dropdown>
    </ul>
    <modal
      :show.sync="ShowNotif"
      class="modal-notice"
      footerClasses="justify-content-center"
      type="notice"
    >
    <!-- type="mini" -->
      <h3 slot="header" class="title_notif">
        Notifications
      </h3>
      <li class="list-notif">
        <!-- Admin notification -->
        <template v-if="CurrentLaw === 'ad' || CurrentLaw === 'tem' || CurrentLaw === 'ma'">
          <Notif_Admin @nb_notifAdm="emit_notifAdm"></Notif_Admin>
        </template>
        <!-- Admin notification -->
        
        <!-- Annonceur notification -->
        <template v-if="CurrentLaw === 'an'">
          <Notif_An @nb_notifAn="emit_notifAn"></Notif_An>
        </template>
        <!-- Annonceur notification -->

        <!-- Editeur notification -->
        <template v-if="CurrentLaw === 'af'">
          <Notif_Af @nb_notifAf="emit_notifAf"></Notif_Af>
        </template>
        <!-- Editeur notification -->
      </li>
    </modal>
  </base-nav>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import Notif_Admin from './Notification/Notification_Admin'
import Notif_An from './Notification/Notification_Annonceur'
import Notif_Af from './Notification/Notification_Editeur'
import gql from 'graphql-tag'
 
export default {
  watch: {
    '$route' () {
      if(this.$router.currentRoute.path === "/dashboard" || this.$router.currentRoute.path ===  "/dashboard-annonceur" || this.$router.currentRoute.path === "/dashboard-editeur") {
        this.showhome = false
      }
      else {
        this.showhome = true
      }
    },
    nb_notifAdm(value) {
      if (value)
        this.showNotifOnLogin()
    },
    nb_notifAn(value) {
      if (value)
        this.showNotifOnLogin()
    },
    nb_notifAf(value) {
      if (value)
        this.showNotifOnLogin()
    }
  },
  created() {
    if(this.$router.currentRoute.path === "/dashboard" || this.$router.currentRoute.path ===  "/dashboard-annonceur" || this.$router.currentRoute.path === "/dashboard-editeur") {
      this.showhome = false
    }
    else {
      this.showhome = true
    }
  },
  components: {
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    Modal,
    Notif_Admin,
    Notif_An,
    Notif_Af
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      nb_notifAdm: 0,
      nb_notifAn: 0,
      nb_notifAf: 0,
      ShowNotif: false,
      showhome: false,
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      lawForCadeau: (JSON.parse(localStorage.getItem('utilisateur')).law == 'af') ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 2375) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 2094) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 2314) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 2047) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 2538) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 464) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 463) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 462) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 459) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 457) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 263) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 458) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 56) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 469) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 50) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 2164) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 253) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 2480) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 2614) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 2534) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 63) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 36) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 36) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 3372) ||
      (JSON.parse(localStorage.getItem('utilisateur')).id == 3144) ? true : false,
      searchQuery: '',
      CurrentLaw: JSON.parse(localStorage.getItem('utilisateur')).law,
      hasMultiLaw: JSON.parse(localStorage.getItem('utilisateur')).multi_droit,
      imageUrl: JSON.parse(localStorage.getItem('utilisateur')).image,
      First_name: JSON.parse(localStorage.getItem('utilisateur')).first_name,
      Last_name: JSON.parse(localStorage.getItem('utilisateur')).last_name,
      id: JSON.parse(localStorage.getItem('utilisateur')).id,
      id_clien_leadmarket: JSON.parse(localStorage.getItem('utilisateur')).id_clien_leadmarket,
      society: JSON.parse(localStorage.getItem('utilisateur')).society,
      disabledSwitch: false,
      soldeCadeau: 0,
      skipQuery: (JSON.parse(localStorage.getItem('utilisateur')).law == 'af' || JSON.parse(localStorage.getItem('utilisateur')).law == 'ad' || JSON.parse(localStorage.getItem('utilisateur')).law == 'ma') ? false : true
    };
  },
  apollo: {
    getCompteCadeaux: {
      client: 'AUTH',
      query: gql`
        query($user_id: Int!, $droit: String) {
          getCompteCadeaux (user_id: $user_id, droit: $droit){
            url
            solde
            message
          }
        }
      `,
      skip() {
        return this.skipQuery
      },
      variables() {
        return {
          user_id: JSON.parse(localStorage.getItem('utilisateur')).id,
          droit: JSON.parse(localStorage.getItem('utilisateur')).law,
        };
      },
      result({ data, loader, networkStatus }) {
        this.skipQuery = true
        this.soldeCadeau = data.getCompteCadeaux.solde
        localStorage.url_cadeau = data.getCompteCadeaux.url
      },
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    generateUrlCadaux() {
      if (
        localStorage.url_cadeau !== null &&
        localStorage.url_cadeau !== "null"
      ) {
        this.skipQuery = false;
        window.open(localStorage.url_cadeau, "_blank");
      } else {
        this.$notify({
              type: "warning",
              message: "0 Points accumulés",
            });
      }
    },
    showNotifOnLogin() {
      if (this.nb_notifAdm || this.nb_notifAn || this.nb_notifAf) {
        if (localStorage.getItem('showNotif') == 'true') {
          this.ShowNotif = true
        }
      }
      localStorage.setItem('showNotif', false)
    },
    emit_notifAdm(nb_notifAdm) {
      this.nb_notifAdm = nb_notifAdm
    },
    emit_notifAn(nb_notifAn) {
      this.nb_notifAn = nb_notifAn
    },
    emit_notifAf(nb_notifAf) {
      this.nb_notifAf = nb_notifAf
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    signOut () {
      this.$store.commit('signOut')
      location.reload()
    },
    async SwitchToEditeur() {
      if(this.sendAxios('af')) {
        await this.sleep(2000);
        // this.$router.push({name: "Editeur Dashboard"})
        // let routeData = this.$router.resolve({name: "Editeur Dashboard"});
        // window.open(routeData.href);
        this.disabledSwitch = false
        window.location.replace("/")
      }
    },
    async SwitchToAnnonceur() {
      if(this.sendAxios('an')) {
        await this.sleep(2000);
        // this.$router.push({name: "Annonceur Dashboard"})
        // let routeData = this.$router.resolve({name: "Annonceur Dashboard"});
        // window.open(routeData.href);
        this.disabledSwitch = false
        window.location.replace("/")
      }
    },
    async sendAxios(law) {
      this.disabledSwitch = true
      let token = localStorage.getItem('token_jwt')
      let formData = new FormData()
      formData.append('id',JSON.parse(localStorage.getItem('utilisateur')).id)
      formData.append('law',law)
      let valide = false
      await this.axios.post("https://api-auth-plf.wee-do-it.net/api/v1/switch_law",formData,{
        headers: { 
          'authorization': `Bearer ${token}` 
        },
      })
      .then(response => {
        if (response.data.code == "U200_02") {
          let utilisateur = JSON.parse(localStorage.getItem('utilisateur'))
          utilisateur.law = response.data.response.utilisateur.law
          utilisateur.id_clien_myaffil = response.data.response.utilisateur.id_clien_myaffil
          localStorage.setItem('utilisateur', JSON.stringify(utilisateur))
          // this.CurrentLaw = response.data.response.utilisateur.law
          valide =  true
        }
      })
      .catch(err => {
        console.log(err)
        valide = false
      })
      return valide
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    replaceUTF_8(string) {
      if(!string) 
        return ''

      for (const [key, value] of Object.entries(this.$store.state.UT8_array)) {
        string = string.replaceAll(value, key)
      }
      
      return string
    }
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
@media only screen and (max-device-width : 640px) {
  .fontsizeLawS{
    font-size: 10px;
    width: 100px
  }
}

.list-notif {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.soldcadeau:hover {
  text-decoration: underline;
}
</style>
