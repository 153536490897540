<template>
  <router-view></router-view>
</template>

<script>
export default {
  watch: {
    '$route' (to, from) {
      document.title = to.meta.title || 'Weedo IT'
    }
  },
  methods: {
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    }
  },
  mounted() {
    this.initializeLayout();
  }
};
</script>

<style lang="scss">
*, *::before, *::after {
  box-sizing: border-box;
}

.fa, .fas {
  font-weight: 900;
}
</style>
