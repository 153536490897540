export default function auth({ next, store, to }) {
    if (!store.getters.auth) {
        return next({
            name: 'Login'
        })
    }
    if (
        JSON.parse(localStorage.getItem('utilisateur')).law == 'ad' ||
        JSON.parse(localStorage.getItem('utilisateur')).law == 'tem' ||
        JSON.parse(localStorage.getItem('utilisateur')).law == 'ma'
    ) {
        return next()
    } else if (JSON.parse(localStorage.getItem('utilisateur')).law == 'an') {
        return next({
            name: 'Annonceur Dashboard'
        })
    }
    return next({
        name: 'Editeur Dashboard'
    })

}