var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{staticStyle:{"background":"linear-gradient(0deg, #2b3277 0%, #588bbd 100%)"},attrs:{"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('table.columns.Categories'),
          icon: 'fa fa-laptop',
          path: '/weedomarket/admin/categories'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('titlePages.Orders'),
          icon: 'tim-icons icon-bag-16',
          path: '/weedomarket/admin/Commandes'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('titlePages.InvalidationLeads'),
          icon: 'far fa-thumbs-down',
          path: '/weedomarket/admin/leadsDevalides'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Stats Clients',
          icon: 'fas fa-chart-line',
          path: '/weedomarket/admin/StatsClients'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('titlePages.MonitoringPurchaseSales'),
          icon: 'fas fa-chart-bar',
          path: '/weedomarket/admin/surveillanceLeads'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('titlePages.StockView'),
          icon: 'fa fa-clipboard',
          path: '/weedomarket/admin/stock'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('titlePages.followUpOfProfitability'),
          icon: 'tim-icons icon-money-coins',
          path: '/weedomarket/admin/SuiviRenatbilite'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('table.columns.Tools'),
          icon: 'tim-icons icon-settings'
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'code promo',
            path: '/weedomarket/admin/codepromo'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'web service',
            path: '/weedomarket/admin/OggoData'
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('titlePages.statisticsMyGustave'),
          icon: 'fas fa-chart-line'
        }}},[_c('sidebar-item',{attrs:{"link":{ 
            name: _vm.$t('SidebarMenu.Global'),
            path: '/weedomarket/admin/stats/statGustave'
          }}}),_c('sidebar-item',{attrs:{"link":{ 
            name: 'Clients',
            path: '/weedomarket/admin/stats/statClientsGustave'
          }}}),_c('sidebar-item',{attrs:{"link":{ 
            name: _vm.$t('titlePages.webserviceMonitoring'),
            path: '/weedomarket/admin/stats/tempReponseWS'
          }}}),_c('sidebar-item',{attrs:{"link":{ 
            name: _vm.$t('titlePages.transactionalEmail'),
            path: '/weedomarket/admin/stats/stats_mg'
          }}})],1)]}}])},[_c('div',{staticClass:"logo"},[_c('a',{staticClass:"simple-text logo-mini",attrs:{"href":""}},[_c('img',{attrs:{"src":_vm.logo,"alt":"app-logo"}})]),_c('a',{staticClass:"simple-text logo-normal",attrs:{"href":""}},[_vm._v("\n        Weedo market\n      ")])]),_vm._t("default")],2),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":"blue"}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }