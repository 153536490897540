<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a href="https://twitter.com/weedo_it" class="nav-link" target="_blank">
            <i class="fab fa-twitter-square"></i>
          </a>
        </li>
        <li class="nav-item">
          <a href="https://www.facebook.com/WEEDOIT/" class="nav-link" target="_blank">
            <i class="fab fa-facebook-square"></i>
          </a>
        </li>
        <li class="nav-item">
          <a href="https://fr.linkedin.com/company/weedoit-fr" class="nav-link" target="_blank">
            <i class="fab fa-linkedin"></i>
          </a>
        </li>
        <li class="nav-item">
          <a href="https://www.instagram.com/weedoitagency/" class="nav-link" target="_blank">
            <i class="fab fa-instagram"></i>
          </a>
        </li>
        <li class="nav-item">
          <a href="https://www.instagram.com/weedoitagency/" class="nav-link" target="_blank">
            <i class="fab fa-youtube"></i>
          </a>
        </li>
        <li class="nav-item" v-if="ClientWeedoMarket && $route.fullPath.includes('/weedomarket')">
          <a href="https://weedoit-media.s3.eu-central-1.amazonaws.com/WEEDOMARKET/CGV_WeedoMarket.pdf" class="nav-link" target="_blank">
            CGV WEEDO MARKET
          </a>
        </li>
        <li class="nav-item" v-if="ClientWeedoPerf && $route.fullPath.includes('/myaffil')">
          <a href="https://weedoit-media.s3.eu-central-1.amazonaws.com/CGV/CGV-weedoperf+%26+weedocoreg.pdf" class="nav-link" target="_blank">
            CGV WEEDO PERF
          </a>
        </li>
      </ul>
      <div class="copyright">
        ©  {{year}}
        made with <i class="tim-icons icon-heart-2"></i> by
        Weedo IT for a better web.
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      ClientWeedoMarket: (JSON.parse(localStorage.getItem('utilisateur')).law == 'an' && JSON.parse(localStorage.getItem('utilisateur')).id_clien_leadmarket),
      ClientWeedoPerf: (JSON.parse(localStorage.getItem('utilisateur')).law == 'an' || JSON.parse(localStorage.getItem('utilisateur')).law == 'af'),
    };
  }
};
</script>
<style></style>
