<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      style="background: linear-gradient(0deg, #070707 0%, #2f2f2f 100%)"
      short-title=""
      title=""
    >
      <div class="logo">
        <a href="" class="simple-text logo-mini">
          <img :src="logo" alt="" />
        </a>
        <a href="" class="simple-text logo-normal"> WEEDOIT </a>
      </div>

      <ul class="nav" v-if="Admin" style="">
        <sidebar-item
          style="
            border-left-width: 4px;
            border-left-style: solid;
            margin-bottom: 0;
          "
          class="General"
          :link="{
            name: 'General',
            icon: 'tim-icons icon-settings-gear-63',
          }"
        >
          <sidebar-item
            v-if="PathCadeau"
            :link="{
              name: $t('titlePages.giftBalance'),
              path: '/StatsTopCaAnOrAf',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('SidebarMenu.MembersList'),
              path: '/Membreslist',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('SidebarMenu.OIList'),
              path: '/OIlist',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('titlePages.listOfPrepayments'),
              path: '/ListePresPaiements',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Logs',
              path: '/Logs',
            }"
          ></sidebar-item>
          <sidebar-item
            v-if="droit === 'ad'"
            :link="{
              name: $t('titlePages.pushNotifications'),
              path: '/pushNotifications/statistiques',
            }"
          ></sidebar-item>
          <sidebar-item
          v-if="SupperAdmin"
            :link="{
              name: $t('titlePages.monthlyReport'),
              path: '/report',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('titlePages.customerValidationCipher'),
              path: '/stats-valides/stats-valides-myaffil',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('titlePages.clientSynthesis'),
              path: '/report/CaClient',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'CA PAR LEVIERS',
              path: '/statsSocialMedia',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Leads Teletech',
              path: '/TeletechLeads',
            }"
          ></sidebar-item>
          <sidebar-item
            v-if="RoutesRunTime"
            :link="{
              name: 'stats temps reel mongustave',
              path: '/temps-reel/mongustave',
            }"
          ></sidebar-item>
          <sidebar-item
            v-if="RoutesRunTime"
            :link="{
              name: 'stats temps reel weedomarket',
              path: '/temps-reel/weedomarket',
            }"
          ></sidebar-item>
         <!--  <sidebar-item
            v-if="droit === 'ad'"
            :link="{
              name: $t('titlePages.bedrockIntercation'),
              path: '/bedrock/interactions',
              target: '_blank'
            }"
          ></sidebar-item> -->
        </sidebar-item>
      </ul>

      <ul class="nav" v-if="Editeur" style="">
        <sidebar-item
          style="
            border-left-width: 4px;
            border-left-style: solid;
            margin-bottom: 0;
          "
          class="General"
          :link="{
            name: 'General',
            icon: 'tim-icons icon-settings-gear-63',
          }"
        >
          <sidebar-item
            :link="{
              name: 'Mes appels à facture',
              short_name: 'AAF',
              path: '/AppelFactureEditeur',
            }"
          >
          </sidebar-item>

          <sidebar-item
            :link="{
              name: $t('SidebarMenu.infosBancaire'),
              path: '/infos-bancaire/affilie-france',
            }"
          >
          </sidebar-item>

          <sidebar-item
            v-if="teletech"
            :link="{
              name: 'suivi leads teletech',
              path: '/TeletechLeads',
            }"
          >
          </sidebar-item>
        </sidebar-item>

        <!-- 2197 -->

      </ul>

      <ul class="nav" v-if="Annonceur" style="">
        <sidebar-item
          style="
            border-left-width: 4px;
            border-left-style: solid;
            margin-bottom: 0;
          "
          class="General"
          :link="{
            name: 'General',
            icon: 'tim-icons icon-settings-gear-63',
          }"
        >
          <sidebar-item
            :link="{
              name: 'Mes Factures',
              path: '/FactureAnnonceur',
            }"
          ></sidebar-item>

          <sidebar-item
            :link="{
              name: 'Mes Documents Oi',
              short_name: 'OI',
              path: '/mes-documents-oi',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Mes Prépaiments',
              path: '/mes-prepaiement',
            }"
          ></sidebar-item>
        </sidebar-item>
      </ul>

      <ul class="nav">
        <li class="sidebar_ico purple">
          <router-link :to="{ name: MyAffil }">
            <img :src="logo" alt="" class="float-left" />
            <p>Weedo Perf</p>
          </router-link>
        </li>
        <li class="sidebar_ico blue">
          <router-link :to="{ name: Market }">
            <img :src="logo" alt="" class="float-left" />
            <p>Weedo Market</p>
          </router-link>
        </li>
        <li class="sidebar_ico green">
          <router-link :to="{ name: MyCoreg }">
            <img :src="logo" alt="" class="float-left" />
            <p>Weedo Coreg</p>
          </router-link>
        </li>
        <li class="sidebar_ico orange">
          <router-link :to="{ name: Data }">
            <img :src="logo" alt="" class="float-left" />
            <p>Weedo Data</p>
          </router-link>
        </li>
        <li class="sidebar_ico mongustave">
          <router-link :to="{ name: Mongustave }">
            <img :src="logoMG" alt="" class="float-left" />
            <p>Mon Gustave</p>
          </router-link>
        </li>
        <li class="sidebar_ico weedomax" v-if="Annonceur || Editeur">
          <router-link :to="{ name: CadeauxPrive }">
            <img :src="logoWMax" alt="" class="float-left" />
            <p>WEEDO MAX</p>
          </router-link>
        </li>
        <li class="sidebar_ico mediumseagreen" v-if="Admin || WeedoCheckClient">
          <router-link :to="{ name: WeedoCheck }">
            <img :src="logo" alt="" class="float-left" />
            <p>Weedo Check</p>
          </router-link>
        </li>
      </ul>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <sidebar-share style="background: rgba(0, 0, 0, 0.3)"> </sidebar-share>
    <div class="main-panel" :data="'blue'">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import SidebarShare from "./SidebarSharePlugin";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import SidebarFixedToggleButton from "./SidebarFixedToggleButton.vue";
import { SlideYDownTransition, ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
    SidebarShare,
  },
  data() {
    return {
      MyAffil: "",
      MyCoreg: "",
      CadeauxPrive: "",
      Data: "",
      Market: "",
      Mongustave: "",
      PathCadeau:
        JSON.parse(localStorage.getItem("utilisateur")).email ==
          "k.ghalem@weedoit.fr" ||
        JSON.parse(localStorage.getItem("utilisateur")).email ==
          "a.gorani@weedoit.fr" ||
        JSON.parse(localStorage.getItem("utilisateur")).email ==
          "s.boukbir@weedoit.fr" ||
        JSON.parse(localStorage.getItem("utilisateur")).email ==
          "s.rahmani@weedoit.fr",
      WeedoCheck: "",
      Annonceur: JSON.parse(localStorage.getItem("utilisateur")).law == "an",
      Editeur: JSON.parse(localStorage.getItem("utilisateur")).law == "af",
      teletech: JSON.parse(localStorage.getItem("utilisateur")).id === 2197 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === "2197",
      Admin:
        JSON.parse(localStorage.getItem("utilisateur")).law == "ad" ||
        JSON.parse(localStorage.getItem("utilisateur")).law == "tem" ||
        JSON.parse(localStorage.getItem("utilisateur")).law == "ma",
      RoutesRunTime:
        JSON.parse(localStorage.getItem("utilisateur")).id === 31,
      SupperAdmin:
        JSON.parse(localStorage.getItem("utilisateur")).id === 39 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 44 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 228 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 253 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 50 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 62 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 1119 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 458 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2607 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 469 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2631 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2630 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2641 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2644 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 1864 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === "1864" ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 3166 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === "3166" ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 3167 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === "3167" ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2427 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2047 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2094 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 31 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 464 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2972,
      allowMarket:
        JSON.parse(localStorage.getItem("utilisateur")).id === 44 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2047 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2081 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 50 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 39 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2058 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2144 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2876 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 63 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 36 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 56 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2161 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2313 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2314 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 253 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2440 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2441 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2534 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2538 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2572 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 31 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 469 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 464 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2094 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2427 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2642 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2654 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 1864 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === "1864" ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2032 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 3045 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 3061 ||
        JSON.parse(localStorage.getItem("utilisateur")).id === 2972,
        WeedoCheckClient: !!JSON.parse(localStorage.getItem("utilisateur")).weedo_check,
        id_clien_leadmarket: JSON.parse(localStorage.getItem("utilisateur"))
          .id_clien_leadmarket,
      logo: "/img/weedo-it-logo-blanc.png",
      logoMG: "/img/ico-mongustave.png",
      logoWMax: "/img/ico-weedomax.png",
      sidebarBackground: "primary", //vue|blue|orange|green|red|primary
      droit : '',
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("sidebar");
        initScrollbar("main-panel");
        initScrollbar("sidebar-wrapper");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
  },
  mounted() {
    this.initScrollbar();
    this.droit = JSON.parse(localStorage.getItem("utilisateur")).law
    if (
      JSON.parse(localStorage.getItem("utilisateur")).law == "ad" ||
      JSON.parse(localStorage.getItem("utilisateur")).law == "tem" ||
      JSON.parse(localStorage.getItem("utilisateur")).law == "ma"
    ) {
      this.MyAffil = "Dashboard MyAffil Admin";
      this.MyCoreg = "Home Weedo Coreg";
      this.Data = "Home Weedo Data Admin";
      this.Mongustave = "Dashboard Mon Gustave Admin";
      if (this.allowMarket) {
        this.Market = "Categorie LeadMarket Admin";
      } else {
        this.Market = "";
      }
      this.WeedoCheck = "Scenarios WeedoCheck Admin";
    } else if (JSON.parse(localStorage.getItem("utilisateur")).law == "af") {
      this.MyAffil = "Dashboard MyAffil Editeur";
      this.MyCoreg = "Home Weedo Coreg";
      this.Data = "Home Weedo Data";
      this.CadeauxPrive = "Dashboard Mes Cadeaux";
      this.Market = "";
      this.WeedoCheck = "Scenarios WeedoCheck Editeur";
    } else if (JSON.parse(localStorage.getItem("utilisateur")).law == "an") {
      this.MyAffil = "Dashboard MyAffil Annonceur";
      this.MyCoreg = "Home Weedo Coreg";
      this.CadeauxPrive = "Dashboard Mes Cadeaux";
      this.Data = "Home Weedo Data";
      if (this.id_clien_leadmarket) {
        this.Market = "Dashboard Leadmarket Annonceur";
        this.Mongustave = "Dashboard Mon Gustave Annoncer";
      } else {
        this.Market = "";
        this.Mongustave = "";
      }
      this.WeedoCheck = "Scenarios WeedoCheck Annonceur";
    }
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

.sidebar_ico {
  border-left-width: 4px;
  border-left-style: solid;
  margin-bottom: 40px;
}

.General p {
  font-size: 14px;
}

.sidebar_ico.purple {
  border-color: #51357c;
}
.sidebar_ico.green {
  border-color: #28829f;
}
.sidebar_ico.orange {
  border-color: #e65f25;
}
.sidebar_ico.blue {
  border-color: #2b3277;
}
.sidebar_ico.mongustave {
  border-color: #0cb3c7;
}
.sidebar_ico.mediumseagreen {
  border-color: #3CB371;
}
.sidebar_ico p,
.active_menu a {
  padding-left: 42px;
  font-size: 14px;
}

.sidebar_ico a,
.active_menu a.logo-mini {
  border-radius: 100% !important;
  height: 40px;
  width: 40px !important;
}

.sidebar_ico.purple a,
.active_menu.purple a {
  background: #51357c !important;
}
.sidebar_ico.green a,
.active_menu.green a {
  background: #28829f !important;
}
.sidebar_ico.orange a,
.active_menu.orange a {
  background: #e65f25 !important;
}
.sidebar_ico.blue a,
.active_menu.blue a {
  background: #2b3277 !important;
}
.sidebar_ico.mediumseagreen a,
.active_menu.mediumseagreen a {
  background: #3CB371 !important;
}


.green {
  background: linear-gradient(0deg, #28829f 0%, #54c4d5 100%) !important;
}
.orange {
  background: linear-gradient(0deg, #e65f25 0%, #f3ac9a 100%) !important;
}
.blue {
  background: linear-gradient(0deg, #2b3277 0%, #588bbd 100%) !important;
}
.purple {
  background: linear-gradient(0deg, #51357c 0%, #988fc3 100%) !important;
}

.sidebar_ico a,
.sidebar_ico.purple,
.sidebar_ico.green,
.sidebar_ico.orange,
.sidebar_ico.blue,
.active_menu,
.active_menu a.logo-normal .logo a {
  background: none !important;
}

.off-canvas-sidebar .nav li.mongustave > a,
.sidebar .nav li.mongustave > a {
  padding: 0;
}

.sidebar_ico.mongustave p {
  margin-left: 7px;
  padding-top: 9px;
}

.sidebar_ico.weedomax {
  border-color: #ec277a;
}

.active_menu.weedomax a,
.sidebar_ico.weedomax a {
  background: #ec277a !important;
}
</style>
