import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store/index'

// configure router
const router = new VueRouter({
    mode: 'history',
    routes,
    linkActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        try {
            let elmnt = document.getElementById('TOPPAGE');
            elmnt.scrollIntoView(false);
        } catch (error) {}
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
        ...context
    })
})

export default router;