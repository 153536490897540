export default function auth({ next, store, to }) {
    if (!store.getters.auth) {
        return next({
            name: 'Login'
        })
    }
    if ( !! JSON.parse(localStorage.getItem("utilisateur")).weedo_check) {
      return next()
    }

    return next({
      name: 'Home'
    })
}
