/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.2.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import store from './store';
import Axios from "axios";
import VueAxios from "vue-axios";
import VueJwtDecode from 'vue-jwt-decode'
import VuePhoneNumberInput from 'vue-phone-number-input';
import '@/assets/css/vue-phone-number-input.css';
import Hotjar from 'vue-hotjar'
import { BootstrapVue } from 'bootstrap-vue'
import fullscreen from 'vue-fullscreen'

// router setup
import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker'
import { createProvider } from './vue-apollo'
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueAxios, Axios);
Vue.use(VueJwtDecode);
Vue.use(BootstrapVue)
Vue.use(fullscreen)
Vue.use (Hotjar, {
   id: '2096863' // Hotjar Site ID
})

Vue.component('vue-phone-number-input', VuePhoneNumberInput);


/* eslint-disable no-new */
new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store,
    apolloProvider: createProvider(),
    i18n
});