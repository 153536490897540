<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-share style="background:rgba(0, 0, 0, 0.3)"> </sidebar-share>
    <div class="main-panel" :data="'blue'">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  import SidebarShare from './SidebarSharePlugin';
  import DashboardNavbar from './DashboardNavbar.vue';
  import { SlideYDownTransition } from 'vue2-transitions';
  
  export default {
    components: {
      DashboardNavbar,
      SlideYDownTransition,
      SidebarShare
    },
    data() {
      return {
        logo: '/img/weedo-it-logo-blanc.png',
        sidebarBackground: 'primary' //vue|blue|orange|green|red|primary
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      }
    }
  };
</script>
