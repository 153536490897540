<template>
  <div>
    <li 
      v-if="Nb_Users_Waiting"
      @click="GoToMembersWaiting()"
      class="li-notif"
    >
      <i class="tim-icons icon-single-02"></i> &nbsp;
      <label>
        VOUS AVEZ <b>{{ Nb_Users_Waiting }}</b> MEMBRE<template v-if="Nb_Users_Waiting!=1">S</template> EN ATTENTE 
      </label>
    </li>
    <!-- <div class="dropdown-divider"></div> -->
    <li 
      v-if="Nb_Postules_Waiting"
      @click="GoToPostules()"
      class="li-notif"
    >
      <i class="tim-icons icon-send"></i> &nbsp;
      <label>
        VOUS AVEZ <b>{{ Nb_Postules_Waiting }}</b> DEMANDE<template v-if="Nb_Postules_Waiting!=1">S</template> DE DIFFUSION EN ATTENTE 
      </label>
    </li>
    <li 
      v-if="Nb_OI_Waiting"
      @click="GoToDocumentOi()" 
      class="li-notif"
    >
      <i class="el-icon-document"></i> &nbsp;
      <label>
        VOUS AVEZ <b>{{ Nb_OI_Waiting }}</b> OI SIGNÉ<template v-if="Nb_OI_Waiting!=1">S</template>
      </label>
    </li>
    <li 
      v-if="Nb_Programe_Wainting"
      @click="GoToProgrames()"
      class="li-notif"
    >
      <i class="fa fa-bullhorn"></i> &nbsp;
      <label>
        VOUS AVEZ <b>{{ Nb_Programe_Wainting }}</b> NOUVEAU<template v-if="Nb_Programe_Wainting!=1">X</template> PROGRAMME<template v-if="Nb_Programe_Wainting!=1">S</template>
      </label>
    </li>
    <li 
      v-if="Nb_Praipayment_Wainting || Nb_Praipayment_Wainting_MARKET"
      @click="GoToPaiments()" 
      class="li-notif"
    >
      <i class="tim-icons icon-money-coins"></i> &nbsp;
      <label>
        IL Y A  <b>{{ (Nb_Praipayment_Wainting + Nb_Praipayment_Wainting_MARKET) }}</b> PRÉPAIEMENT<template v-if="(Nb_Praipayment_Wainting + Nb_Praipayment_Wainting_MARKET)!=1 ">S</template>
      </label>
    </li>

    <li
      v-if="!Nb_Users_Waiting && !Nb_Postules_Waiting && !Nb_OI_Waiting && !Nb_Programe_Wainting && !Nb_Praipayment_Wainting && Nb_Praipayment_Wainting_MARKET"
      class="li-notif"
    >
      <h5>
        VOUS N'AVEZ AUCUNE NOTIFICATION
      </h5>
    </li>

  </div>
</template>

<script>
  import gql from 'graphql-tag'

  export default {
    watch: {
      totalnotif(value) {
        this.$emit('nb_notifAdm', value);
      }
    },
    computed: {
      totalnotif() {
        let total = 0
        total = this.Nb_Users_Waiting + this.Nb_Postules_Waiting +
                this.Nb_OI_Waiting + this.Nb_Programe_Wainting + this.Nb_Praipayment_Wainting + this.Nb_Praipayment_Wainting_MARKET
        return total
      }
    },
    apollo: {
      Nb_Users_Waiting: {
        client: 'AUTH',
        query: gql`
          query {
            getMembreAttent {
              nb
            }
          }
        `,
        fetchPolicy: 'no-cache',
        update: data => data.getMembreAttent.nb
      },
      Nb_Postules_Waiting: {
        client: "AUTH",
        query: gql`
          query {
            getPostuleAttent {
              nb
            }
          }
        `,
        fetchPolicy: 'no-cache',
        update: data => data.getPostuleAttent.nb
      },
      Nb_OI_Waiting: {
        client: "AUTH",
        query: gql`
          query {
            getDocumentOiAttent {
              nb
            }
          }
        `,
        fetchPolicy: 'no-cache',
        update: data => data.getDocumentOiAttent.nb
      },
      Nb_Programe_Wainting: {
        client: "AUTH",
        query: gql`
          query {
            getProgrammeAttent{
              nb
            }
          }
        `,
        fetchPolicy: 'no-cache',
        update: data => data.getProgrammeAttent.nb
      },
      Nb_Praipayment_Wainting: {
        client: "AUTH",
        query: gql`
          query {
            getpresPaiementAttent{
              nb
            }
          }
        `,
        fetchPolicy: 'no-cache',
        update: data => data.getpresPaiementAttent.nb
      },
      Nb_Praipayment_Wainting_MARKET: {
        client: "MARKET",
        query: gql`
          query {
            lastTotalPrepaiements{
              number
            }
          }
        `,
        fetchPolicy: 'no-cache',
        update: data => data.lastTotalPrepaiements.number
      },
    },
    data() {
      return {
      }
    },
    methods: {
      GoToMembersWaiting() {
        if (this.$router.history.current.name != "liste membres Admin") {
          localStorage.setItem("ShowWaitingMembers","true")
          this.$router.push({name: "liste membres Admin"})
        }
      },
      GoToPostules() {
        if (this.$router.history.current.name != "liste postule My Affil Admin")
          this.$router.push({name: "liste postule My Affil Admin"})
      },
      GoToDocumentOi() {
        if (this.$router.history.current.name != "liste de OI disponible Admin")
          this.$router.push({name: "liste de OI disponible Admin"})
      },
      GoToProgrames() {
        if (this.$router.history.current.name != "liste programmes My Affil Admin")
          this.$router.push({name: "liste programmes My Affil Admin"})
      },
      GoToPaiments() {
        if (this.$router.history.current.name != "liste pre-paiement admin")
          this.$router.push({name: "liste pre-paiement admin"})
      }
    },
  }
</script>

<style scoped>
  .li-notif {
    margin-top: -1px; /* Prevent double borders */
    color: white;
  }
  .li-notif:hover {
    color: black;
  }
  .li-notif label {
    font-size: small;
    cursor: pointer;
  }

  .li-notif i {
    cursor: pointer;
  }
</style>