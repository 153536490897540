<template>
  <div>

    <li 
      v-if="Nb_OI_Waiting"
      @click="GoToDocumentOi()"
      class="li-notif"
    >
      <i class="el-icon-document"></i>&nbsp;
      <label>
        VOUS AVEZ <b>{{ Nb_OI_Waiting }}</b> OI À SIGNER
      </label>
    </li>

    <li 
      v-if="Nb_Facture_Waiting"
      @click="GoToFactures()"
      class="li-notif"
    >
      <i class="tim-icons icon-wallet-43"></i>&nbsp;
      <label>
        VOTRE FACTURE EST DISPONIBLE
      </label>
    </li>

    <li
      v-if="!Nb_OI_Waiting && !Nb_Facture_Waiting"
      class="li-notif"
    >
    <h5>
      VOUS N'AVEZ AUCUNE NOTIFICATION
    </h5>
    </li>

  </div>
  </template>

<script>
  import gql from 'graphql-tag'

  export default {
    watch: {
      totalnotif(value) {
        this.$emit('nb_notifAn', value);
      }
    },
    computed: {
      totalnotif() {
        let total = 0
        total = this.Nb_OI_Waiting + this.Nb_Facture_Waiting
        return total
      }
    },
    apollo: {
      Nb_OI_Waiting: {
        client: 'AUTH',
        query: gql`
          query ($id_utilisateur: Int) {
            getDocumentOiASigneAn (id_utilisateur: $id_utilisateur) {
              nb
            }
          }
        `,
        fetchPolicy: 'no-cache',
        variables: {
          id_utilisateur: (JSON.parse(localStorage.getItem('utilisateur')) != null) ? JSON.parse(localStorage.getItem('utilisateur')).id : null
        },
        update: data => data.getDocumentOiASigneAn.nb
      },
      Nb_Facture_Waiting: {
        client: 'AUTH',
        query: gql`
          query ($id_client_perf: Int, $id_client_coreg: Int) {
            getfactureAttentAn (id_client_perf: $id_client_perf, id_client_coreg: $id_client_coreg) {
              nb
            }
          }
        `,
        fetchPolicy: 'no-cache',
        variables: {
          id_client_perf: (JSON.parse(localStorage.getItem('utilisateur')) != null) ? JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil : null,
          id_client_coreg: (JSON.parse(localStorage.getItem('utilisateur')) != null) ? JSON.parse(localStorage.getItem('utilisateur')).id_clien_mycoreg : null
        },
        update: data => data.getfactureAttentAn.nb
      },
    },
    methods: {
      GoToDocumentOi() {
        if (this.$router.history.current.name != "liste des OI disponible Annonceur")
          this.$router.push({name: "liste des OI disponible Annonceur"})
      },
      GoToFactures() {
        if (this.$router.history.current.name != "Facture Annonceur")
          this.$router.push({name: "Facture Annonceur"})
      }
    },
  }
</script>

<style scoped>
  .li-notif {
    margin-top: -1px; /* Prevent double borders */
    color: white;
  }
  .li-notif:hover {
    color: black;
  }
  .li-notif label {
    font-size: small;
    cursor: pointer;
  }

  .li-notif i {
    cursor: pointer;
  }
</style>