<template>
  <div class="fixed-plugin" v-click-outside="closeDropDown">
    <div class="dropdown show-dropdown" :class="{ show: isOpen }">
      <a data-toggle="dropdown" class="settings-icon">
        <i class="fa fa-cog fa-2x" @click="toggleDropDown"> </i>
      </a>
      <ul class="dropdown-menu" :class="{ show: isOpen }">
        <li class="header-title">Sidebar Mini</li>
        <li class="adjustments-line">
          <div class="togglebutton switch-sidebar-mini">
            <span class="label-switch">OFF</span>
            <base-switch
              v-model="sidebarMini"
              @input="minimizeSidebar"
            ></base-switch>
            <span class="label-switch label-right">ON</span>
          </div>

          <div class="togglebutton switch-change-color mt-2">
            <span class="label-switch">LIGHT MODE</span>
            <base-switch v-model="darkMode"></base-switch>
            <span class="label-switch label-right">DARK MODE</span>
          </div>

          <div class="togglebutton switch-change-color mt-2" v-if="AllowTranslate">
            <span class="label-switch">
              <img src="img/flag/RoyaumeUni.png" class="img-flag" />
            </span>
              <base-switch v-model="switchLang" :disabled="disable"></base-switch>
            <span class="label-switch label-right">
              <img src="img/flag/france.png" class="img-flag" />
            </span>
          </div>
        </li>

        <li class="button-container mt-4">

        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import { BaseSwitch } from 'src/components';

  export default {
    watch: {
      darkMode(type) {
        localStorage.setItem("darkMode", type)
        let docClasses = document.body.classList;
        if (type) {
          docClasses.remove('white-content');
        } else {
          docClasses.add('white-content');
        }
      },
      async switchLang(value) {
        this.disable = true
        if (value) {
          localStorage.setItem('lang', 'fr')
        } else {
          localStorage.setItem('lang', 'en')
        }
        await this.sleep(1000);
        location.reload()
        this.disable = false
      }
    },
    created() {
      if (localStorage.getItem('darkMode') == 'true' || localStorage.getItem('darkMode') == null) {
        this.darkMode = true
      }
      else {
        this.darkMode = false
      }
    },
    name: 'sidebar-share',
    components: {
      BaseSwitch
    },
    props: {
      backgroundColor: String
    },
    data() {
      return {
        sidebarMini: true,
        darkMode: true,
        isOpen: false,
        disable: false,
        sidebarColors: [
          { color: 'primary', active: false, value: 'primary' },
          { color: 'vue', active: true, value: 'vue' },
          { color: 'info', active: false, value: 'blue' },
          { color: 'success', active: false, value: 'green' },
          { color: 'warning', active: false, value: 'orange' },
          { color: 'danger', active: false, value: 'red' }
        ],
        switchLang: !(localStorage.getItem('lang') == "en"),
        AllowTranslate: JSON.parse(localStorage.getItem("utilisateur")).id === 39 ||
                        JSON.parse(localStorage.getItem("utilisateur")).id === 44 ||
                        JSON.parse(localStorage.getItem("utilisateur")).id === 63
      };
    },
    methods: {
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      toggleDropDown() {
        this.isOpen = !this.isOpen;
      },
      closeDropDown() {
        this.isOpen = false;
      },
      toggleList(list, itemToActivate) {
        list.forEach(listItem => {
          listItem.active = false;
        });
        itemToActivate.active = true;
      },
      changeSidebarBackground(item) {
        this.$emit('update:backgroundColor', item.value);
        this.toggleList(this.sidebarColors, item);
      },
      minimizeSidebar() {
        this.$sidebar.toggleMinimize();
      }
    }
  };
</script>
<style scoped lang="scss">
  @import '~@/assets/sass/dashboard/custom/variables';

  .settings-icon {
    cursor: pointer;
  }

  .badge-vue {
    background-color: $vue;
  }

  .img-flag {
    width:35px;
    height: 20px;
  }
</style>
