<template>
  <div class="row">
    <router-link :to="{ name: 'prepaiement'}">
      <button v-if="showBatteryLow" class="btn btn-icon btn-round btn-danger blink_soldemarket">
        <i class="fas fa-battery-quarter"></i>
      </button>
      <button v-else class="btn btn-icon btn-round btn-notif">
        <i class="fas fa-battery-quarter"></i>
      </button>
    </router-link>
    <label class="whitcontent" v-if="getSoldeactuel">
      {{ getSoldeactuel.soldeHt.toLocaleString() + ' € HT' }} <br>
      {{ getSoldeactuel.soldeTtc.toLocaleString() + ' € TTC' }}
    </label>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        showBatteryLow: false
      }
    },
    apollo: {
      getSoldeactuel: {
        client: 'MARKET',
        query: require("src/graphql/leadmarket/Annonceur/getSoldeactuel.gql"),
        variables () {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
          }
        },
        fetchPolicy: 'network-only',
        update: data => data.getSoldeactuel,
        result({ data, loader, networkStatus }) {
          let lastsold = 0
          if (data.getSoldeactuel.lastPrepaiement)
            lastsold = data.getSoldeactuel.lastPrepaiement
          
          this.showBatteryLow = (lastsold * 0.2) > data.getSoldeactuel.soldeHt
        }
      }
    }
  }
</script>

<style>

.blink_soldemarket {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>