var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{staticStyle:{"background":"linear-gradient(0deg, #51357c 0%, #988fc3 100%)"},attrs:{"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('SidebarMenu.Dashboard'),
          icon: 'fas fa-desktop',
          path: '/myaffil/admin/dashboard'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('SidebarMenu.Statistics'),
          icon: 'fas fa-chart-line'
        }}},[_c('sidebar-item',{attrs:{"link":{ 
            name: _vm.$t('SidebarMenu.GlobalAndDetailed'),
            path: '/myaffil/admin/stats/statsglobales'
          }}}),_c('sidebar-item',{attrs:{"link":{ 
            name: _vm.$t('SidebarMenu.Goals'),
            path: '/myaffil/admin/objectifs'
          }}}),_c('sidebar-item',{attrs:{"link":{ 
            name: 'Top traffic manager', 
            path: '/myaffil/admin/toptm' 
          }}})],1),_c('sidebar-item',{attrs:{"link":{ 
          name: _vm.$t('SidebarMenu.ProgramList'), 
          icon: 'fa fa-bullhorn',
          path: '/myaffil/admin/prgramslist'
        }}}),_c('sidebar-item',{attrs:{"link":{ 
          name: _vm.$t('SidebarMenu.CampaignsList'), 
          icon: 'tim-icons icon-bullet-list-67',
          path: '/myaffil/admin/liste_campagnes'
        }}}),_c('sidebar-item',{attrs:{"link":{ 
          name: _vm.$t('SidebarMenu.PublishersRequests'), 
          icon: 'fa fa-envelope',
          path: '/myaffil/admin/postulelist'
        }}}),_c('sidebar-item',{attrs:{"link":{ 
          name: _vm.$t('SidebarMenu.ShootsList'), 
          icon: 'fas fa-paper-plane',
          path: '/myaffil/admin/Shootlist'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('table.columns.Tools'),
          icon: 'tim-icons icon-settings'
        }}},[_c('sidebar-item',{attrs:{"link":{ 
            name: 'EMULATION',
            path: '/myaffil/admin/stats/statsAvancees'
          }}}),_c('sidebar-item',{attrs:{"link":{ 
            name: _vm.$t('Pages.dashboard.Statistics') + ' WEEDO MARKET',
            path: '/myaffil/admin/stats/statsleadmarket'
          }}}),_c('sidebar-item',{attrs:{"link":{ 
            name: _vm.$t('titlePages.editorsHavingShot'),
            path: '/myaffil/admin/stats/extractEditeurs'
          }}}),_c('sidebar-item',{attrs:{"link":{ 
            name: 'UPLOAD IMAGES',
            path: '/myaffil/admin/UploadImages'
          }}})],1)]}}])},[_c('div',{staticClass:"logo"},[_c('a',{staticClass:"simple-text logo-mini",attrs:{"href":""}},[_c('img',{attrs:{"src":_vm.logo,"alt":"app-logo"}})]),_c('a',{staticClass:"simple-text logo-normal",attrs:{"href":""}},[_vm._v("\n        Weedo Perf\n      ")])]),_vm._t("default")],2),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":"blue"}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }