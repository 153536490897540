<template>
  <div class="register-page">
    <div class="my-4 mx-auto text-center">
      <a href="https://www.weedoit.fr" target="_blank">
        <img src="/img/weedo-it-logo-blanc2.png" class="img-fluid my-2">
      </a>
    </div>
    <router-view></router-view>    
    <footer class="footer" style="background-color: black;">
      <div class="container-fluid">
        <ul class="nav">
          <li class="nav-item">
            <a href="https://twitter.com/weedo_it" class="nav-link" target="_blank">
              <i class="fab fa-twitter-square"></i>
            </a>
          </li>
          <li class="nav-item">
            <a href="https://www.facebook.com/WEEDOIT/" class="nav-link" target="_blank">
              <i class="fab fa-facebook-square"></i>
            </a>
          </li>
          <li class="nav-item">
            <a href="https://fr.linkedin.com/company/weedoit-fr" class="nav-link" target="_blank">
              <i class="fab fa-linkedin"></i>
            </a>
          </li>
          <li class="nav-item">
            <a href="https://www.instagram.com/weedoitagency/" class="nav-link" target="_blank">
              <i class="fab fa-instagram"></i>
            </a>
          </li>
          <li class="nav-item">
            <a href="https://www.instagram.com/weedoitagency/" class="nav-link" target="_blank">
              <i class="fab fa-youtube"></i>
            </a>
          </li>
        </ul>
        <div class="copyright">
          © <label v-text="year"></label>
          made with <i class="tim-icons icon-heart-2"></i> by
          Weedo IT for a better web.
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { BaseNav } from 'src/components';
import { ZoomCenterTransition } from 'vue2-transitions';

export default {
  components: {
    BaseNav,
    ZoomCenterTransition
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page'
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    }
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      this.showMenu = false;
    },
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    }
  },
  beforeDestroy() {
    this.closeMenu();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  mounted() {
    this.setPageClass();
  },
  watch: {
    $route() {
      this.setPageClass();
    }
  }
};
</script>
<style lang="scss">
body.login {
  background: url("/img/back-login.jpg") no-repeat #000;
  background-size: contain
}
.navbar.auth-navbar {
  top: 0;
}

$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}

.form-control-custom {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.5rem 0.7rem;
    font-size: 0.8rem;
    line-height: 1.42857;
    color: black;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid #cad1d7;
    border-radius: 0.25rem;
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control-custom {
    transition: none;
  }
}

.form-control-custom::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control-custom:focus {
  color: black;
  background-color: #ffffff;
  border-color: black;
  outline: 0;
  box-shadow: none, none;
}

.form-control-custom::-webkit-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control-custom::-moz-placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control-custom::-ms-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control-custom::placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control-custom:disabled,
.form-control-custom[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control-custom:focus::-ms-value {
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
}

</style>
