<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      style="background: linear-gradient(0deg, #2b3277 0%, #588bbd 100%);"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
    >
      <div class="logo">
        <a href="" class="simple-text logo-mini">
          <img :src="logo" alt="app-logo" />
        </a>
        <a href="" class="simple-text logo-normal">
          Weedo market
        </a>
      </div>
      <slot></slot>
      <template slot-scope="props" slot="links">
        <sidebar-item
          :link="{
            name: $t('table.columns.Categories'),
            icon: 'fa fa-laptop',
            path: '/weedomarket/admin/categories'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('titlePages.Orders'),
            icon: 'tim-icons icon-bag-16',
            path: '/weedomarket/admin/Commandes'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('titlePages.InvalidationLeads'),
            icon: 'far fa-thumbs-down',
            path: '/weedomarket/admin/leadsDevalides'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Stats Clients',
            icon: 'fas fa-chart-line',
            path: '/weedomarket/admin/StatsClients'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('titlePages.MonitoringPurchaseSales'),
            icon: 'fas fa-chart-bar',
            path: '/weedomarket/admin/surveillanceLeads'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('titlePages.StockView'),
            icon: 'fa fa-clipboard',
            path: '/weedomarket/admin/stock'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('titlePages.followUpOfProfitability'),
            icon: 'tim-icons icon-money-coins',
            path: '/weedomarket/admin/SuiviRenatbilite'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('table.columns.Tools'),
            icon: 'tim-icons icon-settings'
          }"
        >
          <sidebar-item
            :link="{
              name: 'code promo',
              path: '/weedomarket/admin/codepromo'
            }"
          >
          </sidebar-item>
          <sidebar-item
            :link="{
              name: 'web service',
              path: '/weedomarket/admin/OggoData'
            }"
          >
          </sidebar-item>
        </sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: 'transactions',
            icon: 'fas fa-euro-sign',
            path: '/weedomarket/admin/Transactions'
          }"
        >
        </sidebar-item> -->
        <!-- <sidebar-item
          :link="{
            name: 'Vue sur le stock',
            icon: 'fas fa-box-open',
            path: '/weedomarket/admin/stock'
          }"
        >
        </sidebar-item> -->
        <sidebar-item
          :link="{
            name: $t('titlePages.statisticsMyGustave'),
            icon: 'fas fa-chart-line'
          }"
        >
          <sidebar-item
            :link="{ 
              name: $t('SidebarMenu.Global'),
              path: '/weedomarket/admin/stats/statGustave'
            }"
          >
          </sidebar-item>
          <sidebar-item
            :link="{ 
              name: 'Clients',
              path: '/weedomarket/admin/stats/statClientsGustave'
            }"
          >
          </sidebar-item>
          <sidebar-item
            :link="{ 
              name: $t('titlePages.webserviceMonitoring'),
              path: '/weedomarket/admin/stats/tempReponseWS'
            }"
          >
          </sidebar-item>
          <sidebar-item
            :link="{ 
              name: $t('titlePages.transactionalEmail'),
              path: '/weedomarket/admin/stats/stats_mg'
            }"
          >
          </sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share>
    <div class="main-panel" data="blue">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import SidebarShare from '../SidebarSharePlugin';
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from '../DashboardNavbar.vue';
import ContentFooter from '../ContentFooter.vue';
import DashboardContent from '../Content.vue';
import SidebarFixedToggleButton from '../SidebarFixedToggleButton.vue';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
    SidebarShare
  },
  data() {
    return {
      logo: '/img/weedo-it-logo-blanc.png',
      sidebarBackground: 'orange' //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    }
  },
  mounted() {
    this.initScrollbar();
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
